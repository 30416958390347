<template>
	<footer class="footer text-center"> <span v-text="year"></span> © USSGlobal.com
    </footer>

    <div class="modal" id="modalUserActionRequired">
		<div class="modal-dialog" style="transform: translateX(90%);">
			<div class="modal-content">
				<div class="modal-body">
					User action is required to enable notification sound.
					<button type="button" class="btn btn-primary btn-sm" style="margin-left: 30px;" data-dismiss="modal" v-on:click="enableSound">Enable sound</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import jQuery from "jquery"
	import store from "../../vuex/store"

	import "../../assets/bootstrap/dist/js/bootstrap.js"
	import "../../assets/js/app-style-switcher.js"
	import "../../assets/plugins/bower_components/jquery-sparkline/jquery.sparkline.min.js"
	import "../../assets/js/waves.js"
	import "../../assets/js/sidebarmenu.js"
	import "../../assets/js/custom.js"
	// import "../../assets/plugins/bower_components/chartist/dist/chartist.js"
	// import "../../assets/plugins/bower_components/chartist-plugin-tooltips/dist/chartist-plugin-tooltip.js"
	import "../../assets/js/pages/dashboards/dashboard1.js"

	import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "AppFooter",

		data() {
			return {
				year: new Date().getFullYear()
			}
		},

		methods: {
			enableSound() {
				$("#modalUserActionRequired").modal("hide")
			}
		},

		mounted() {
			$("#modalUserActionRequired").modal("show")
		}
	}
</script>