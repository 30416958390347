<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Invoices</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Invoices</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row" v-if="hasPermission('addInvoice')">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">
						<button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddInvoice" aria-expanded="false" aria-controls="collapseAddInvoice">
						    Add Invoice
						</button>
                	</h3>

                	<div class="collapse" id="collapseAddInvoice">
						<div class="card card-body">
							<form v-on:submit.prevent="addInvoice">
		                		<div class="form-group">
									<label class="form-label">User</label>
									<input type="email" name="email" id="userEmail" placeholder="Enter email (leave empty for un-registered)" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Invoice ID</label>
									<input type="text" name="invoiceId" placeholder="Enter Invoice ID" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Name</label>
									<input type="text" name="name" placeholder="Enter name" class="form-control" required />
								</div>

								<div class="form-group">
									<label class="form-label">Mobile</label>
									<input type="text" name="mobile" placeholder="Enter mobile" class="form-control" />
								</div>
								
								<div class="form-group">
									<label class="form-label">Address</label>
									<textarea class="form-control" name="address" placeholder="Enter address" rows="5"></textarea>
								</div>

								<div class="form-group">
		                            <label class="form-label">Select Payment Method</label>
		                            <select name="paymentMethod" required class="form-control" v-on:change="onchangePaymentMethod" v-model="paymentMethod">
		                                <option value="">Select</option>
		                                <option value="bank">Bank</option>
		                                <option value="cash">Cash</option>
		                                <option value="cheque">Cheque</option>
		                                <option value="paypal">PayPal</option>
		                            </select>
		                        </div>

		                        <div class="form-group" v-if="paymentMethod == 'bank'">
		                    		<label>Select Bank</label>
		                    		<select name="bank" class="form-control">
		                    			<option v-for="(b, index) in banks" v-bind:value="JSON.stringify(b)" v-text="b.accountTitle + ' ' + b.accountNumber"></option>
		                    		</select>
		                    	</div>

		                    	<div class="form-group" v-if="paymentMethod == 'cheque'">
		                    		<label>Enter cheque number</label>
		                    		<input type="text" name="cheque" class="form-control" />
		                    	</div>
								
								<div class="form-group">
									<label class="form-label">Invoice For</label>
									<select name="invoiceFor"
										v-model="invoiceFor"
										required
										class="form-control"
										v-on:change="onchangeDepositFor">
										<!-- <option value="">Select Invoice For</option> -->
		                                <!-- <option value="advanceCarPayment">Advance Car Payment</option> -->
										<!-- <option value="carPayment">Car Payment</option> -->
										<option value="securityDeposit">Security Deposit</option>
									</select>
								</div>

								<div class="form-group" v-if="invoiceFor == 'carPayment' || invoiceFor == 'advanceCarPayment'">
									<label class="form-label">Car</label>

									<select name="auctionCarId" class="form-control">
		                                <option v-for="(car, index) in cars" v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME + ' ' + car.YEAR + ' (LOT: ' + car.LOT + ')'" v-bind:value="car.ID"></option>
		                            </select>
								</div>
								
								<div class="form-group">
									<label class="form-label">Currency</label>
									<input name="currency" required class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Amount</label>
									<input type="number" name="amount" placeholder="Amount" class="form-control" required style="margin-top: 10px;" />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Banking Fee</label>
									<input type="text" name="bankingFee" placeholder="Banking fee" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Shipping Charges</label>
									<input type="text" name="shippingCharges" placeholder="Shipping Charges" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Taxes</label>
									<input type="text" name="taxes" placeholder="Taxes" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Adjust Security Deposit</label>
									<input type="text" name="securityDepositAdjusted" placeholder="Security Deposit" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Total Previous Car Balance</label>
									<input type="text" name="totalPreviousCarBalance" placeholder="Total Previous Car Balance" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Shipping Discount</label>
									<input type="text" name="shippingDiscount" placeholder="Shipping Discount" class="form-control" value="0" required />
								</div>
		                        
		                        <div class="form-group">
									<label class="form-label">Balance Amount</label>
									<input type="text" name="balanceAmount" placeholder="Balance Amount" class="form-control" value="0" required />
								</div>
								
								<div class="form-group">
									<label class="form-label">VAT</label>
									<input type="text" name="vat" placeholder="Enter VAT" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Due Date</label>
									<input type="text" name="dueDate" placeholder="Enter Due Date" class="form-control dueDate" />
								</div>

								<div class="form-group">
									<label class="form-label">Status</label>

									<select name="status" class="form-control">
		                                <option value="pending">Pending</option>
		                                <option value="processed">Processed</option>
		                                <option value="paid">Paid</option>
		                                <option value="declined">Declined</option>
		                            </select>
								</div>

								<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
									Add Invoice

									<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
								</button>
		                	</form>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div class="row" v-if="hasPermission('invoices')">
        	<div class="col-md-12">
        		<div class="white-box">
        			<h3 class="box-title">All Invoices</h3>

        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table class="table" id="invoices-data" v-if="!loading">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Invoice</th>
        							<th>User</th>
        							<th>Amount</th>
        							<th>Payment Method</th>
        							<th>Invoice For</th>
        							<th>Created By</th>
        							<th>Date</th>
        							<th>Due</th>
        							<th>Actions</th>
        						</tr>
        					</thead>

        					<tbody>
        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<td>
        									<table>
        										<tr>
        											<th>Invoice</th>
        											<td>
        												<a v-bind:href="$homeURL + '/invoice/' + d.invoiceId" target="_blank" v-text="d.invoiceId"></a>
        											</td>
        										</tr>

        										<tr>
        											<th>User</th>
        											<td>
        												<span v-text="'Name: ' + d.name"></span> <br />
			        									<span v-text="'Mobile: ' + d.mobile"></span> <br />
			        									<span v-text="'Address: ' + d.address"></span>
        											</td>
        										</tr>

        										<tr>
        											<th>Amount</th>
        											<td>
        												<span v-html="d.currency + ' ' + d.amount + '<br />'"></span>
			        									<span v-if="d.bankingFee > 0" v-html="'Banking Fee: ' + d.bankingFee + '<br />'"></span>
			        									<span v-if="d.shippingCharges > 0" v-html="'Shipping Charges: ' + d.shippingCharges + '<br />'"></span>
			        									<span v-if="d.taxes > 0" v-html="'Taxes: ' + d.taxes + '<br />'"></span>
			        									<span v-if="d.shippingDiscount > 0" v-html="'Shipping Discount: ' + d.shippingDiscount + '<br/>'"></span>
			        									<span v-if="d.securityDepositAdjusted > 0" v-html="'Security Deposit Adjusted: ' + d.securityDepositAdjusted + '<br />'"></span>
			        									<span v-if="d.totalPreviousCarBalance > 0" v-html="'Total Previous Car Balance: ' + d.totalPreviousCarBalance + '<br />'"></span>
			        									<span v-if="d.balanceAmount > 0" v-html="'Balance Amount: ' + d.balanceAmount + '<br />'"></span>
			        									<span v-if="d.vat > 0" v-html="'VAT: ' + d.vat + '<br />'"></span>
        											</td>
        										</tr>

        										<tr>
        											<th>Invoice For</th>
        											<td>
        												<span v-text="d.invoiceFor.replace(/([a-z])([A-Z])/g, '$1 $2')" style="text-transform: capitalize;"></span>
        												<span v-if="d.auctionCarId > 0" v-text="d.auctionCarId"></span>
        											</td>
        										</tr>

        										<tr>
        											<th>Created By</th>
        											<td>
        												<router-link v-bind:to="'/users/edit/' + d.createdById" v-if="user?.role == 'developer' || user?.role == 'superAdmin'">
			        										<span v-text="d.createdByName"></span> <br />
			        										<span v-text="d.createdByEmail"></span>
			        									</router-link>
        											</td>
        										</tr>

        										<tr>
        											<th>Date</th>
        											<td v-text="dateFormat(d.created_at)"></td>
        										</tr>

        										<tr>
        											<th>Due</th>
        											<td v-text="dateFormat(d.dueDate)"></td>
        										</tr>

        										<tr>
        											<th>Actions</th>
        											<td>
        												<router-link v-if="hasPermission('addInvoice')" v-bind:to="'/invoices/edit/' + d.id">Edit</router-link>

        												<br />

        												<a href="javascript:void(0)" v-on:click="showLogs(d.id)">Logs</a>

        												<br />

														<button type="button" v-on:click="deleteInvoice(d.id)" class="btn btn btn-danger text-white" v-if="hasPermission('addInvoice')">Delete</button>
        											</td>
        										</tr>
        									</table>
        								</td>
        							</tr>

        							<tr v-else>
        								<td>
        									<a v-bind:href="$homeURL + '/invoice/' + d.invoiceId" target="_blank" v-text="d.invoiceId"></a>
        								</td>
        								<td>
        									<span v-text="'Name: ' + d.name"></span> <br />
        									<span v-text="'Mobile: ' + d.mobile"></span> <br />
        									<span v-text="'Address: ' + d.address"></span>
        								</td>
        								<td>
        									<span v-html="d.currency + ' ' + d.amount + '<br />'"></span>
        									<span v-if="d.bankingFee > 0" v-html="'Banking Fee: ' + d.bankingFee + '<br />'"></span>
        									<span v-if="d.shippingCharges > 0" v-html="'Shipping Charges: ' + d.shippingCharges + '<br />'"></span>
        									<span v-if="d.taxes > 0" v-html="'Taxes: ' + d.taxes + '<br />'"></span>
        									<span v-if="d.shippingDiscount > 0" v-html="'Shipping Discount: ' + d.shippingDiscount + '<br/>'"></span>
        									<span v-if="d.securityDepositAdjusted > 0" v-html="'Security Deposit Adjusted: ' + d.securityDepositAdjusted + '<br />'"></span>
        									<span v-if="d.totalPreviousCarBalance > 0" v-html="'Total Previous Car Balance: ' + d.totalPreviousCarBalance + '<br />'"></span>
        									<span v-if="d.balanceAmount > 0" v-html="'Balance Amount: ' + d.balanceAmount + '<br />'"></span>
        									<span v-if="d.vat > 0" v-html="'VAT: ' + d.vat + '<br />'"></span>
        								</td>
        								<td>
        									<span v-text="d.paymentMethod" style="text-transform: capitalize;"></span> <br />
        									<span v-if="d.paymentMethod == 'bank'" v-html="getBank(d.bank).name + '<br />' + getBank(d.bank).accountNumber + '<br />' + getBank(d.bank).accountTitle"></span>
        									<span v-if="d.paymentMethod == 'cheque'" v-text="d.cheque"></span>
        								</td>
        								<td>
        									<span v-text="d.invoiceFor.replace(/([a-z])([A-Z])/g, '$1 $2')" style="text-transform: capitalize;"></span>
        									<span v-if="d.auctionCarId > 0" v-text="d.auctionCarId"></span>
        								</td>
        								<td>
        									<router-link v-bind:to="'/users/edit/' + d.createdById" v-if="user?.role == 'developer' || user?.role == 'superAdmin'">
        										<span v-text="d.createdByName"></span> <br />
        										<span v-text="d.createdByEmail"></span>
        									</router-link>
        								</td>
        								<td v-text="dateFormat(d.created_at)"></td>
        								<td v-text="dateFormat(d.dueDate)"></td>

										<td>
											<router-link v-if="hasPermission('addInvoice')" v-bind:to="'/invoices/edit/' + d.id">Edit</router-link>

											<br />

											<button type="button" v-on:click="addInTransaction(d.id)" class="btn btn btn-primary" v-if="d.transactionId == null && hasPermission('addTransaction')">Add in Transaction</button>

											<br />

											<a href="javascript:void(0)" v-on:click="showLogs(d.id)">Logs</a>

											<br />

											<button type="button" v-on:click="deleteInvoice(d.id)" class="btn btn btn-danger text-white" v-if="hasPermission('addInvoice')">Delete</button>
										</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>

        		</div>
        	</div>
        </div>

    </div>

    <div class="modal" id="logsModal">
    	<div class="modal-dialog modal-lg">
    		<div class="modal-content" style="overflow: scroll;">
    			<div class="modal-header">
    				<h3 class="modal-title">Logs</h3>

    				<button type="button" class="close" v-on:click="closeLogsModal" style="background: none; border: none;">
			          <span>&times;</span>
			        </button>
    			</div>

    			<div class="modal-body">
    				<div class="text-center" v-if="loadingLogs">
    					<div class="spinner-border"></div>
    				</div>

    				<table class="table table-bordered table-responsive" id="logs-data" v-if="!loadingLogs">
    					<thead>
    						<tr>
    							<th>Logged By</th>
                            	<th>Action</th>
                            	<th>Date and Time</th>
    						</tr>
    					</thead>

    					<tbody>
    						<tr v-for="(d, index) in logs" v-bind:key="d.id">
    							<td>
									<router-link v-bind:to="'/users/edit/' + d.loggedBy" v-text="d.name + ' | ' + d.email" v-on:click="closeLogsModal">
									</router-link>
								</td>

								<td>
									<pre v-html="getAction(d.action)"></pre>
								</td>
								<td v-text="d.created_at"></td>
    						</tr>
    					</tbody>
    				</table>

    				<div v-if="logPages > 0">
    					<nav>
    						<ul class="pagination">
    							<li v-bind:class="'page-item ' + (page == logPage ? 'active' : '')" v-for="page in logPages">
    								<a href="javascript:void(0)" v-on:click="paginateLogs(page)" v-text="page" class="page-link"></a>
    							</li>
    						</ul>
    					</nav>
    				</div>
    			</div>
    		</div>
    	</div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"
    import jQuery from "jquery"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "InvoicesComponent",

		computed: {
			user() {
				return store.getters.getUser
			}
		},

		data() {
			return {
				isAdding: false,
				currency: "",
				paymentMethod: "bank",
				invoiceFor: "securityDeposit",
				cars: [],
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				loadingLogs: false,
				logPage: 1,
				logs: [],
				logPages: 0,
				logsDataTable: null,
				id: 0,
				banks: []
			}
		},

		methods: {

			deleteInvoice(id) {
				const self = this
				const target = event.target

				swal.fire({
					title: 'Delete Invoice',
					text: "Are you sure you want to delete this invoice ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		target.setAttribute("disabled", "disabled")
		        		target.innerHTML = "Loading..."

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/invoices/destroy",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Delete Invoice", response.data.message, "success")
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
		        			target.innerHTML = "Delete Invoice"
						}
					}
				})
			},

			addInTransaction(id) {
				const self = this
				const target = event.target

				swal.fire({
					title: 'Add in Transaction',
					text: "Are you sure you want to add this invoice in transaction ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, add it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		target.setAttribute("disabled", "disabled")
		        		target.innerHTML = "Loading..."

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/invoices/addInTransaction",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Add in Transaction", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == id) {
						    			self.data[a].transactionId = response.data.transactionId
						    			break
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
		        			target.innerHTML = "Add in Transaction"
						}
					}
				})
			},

			getAction(action) {
				const parts = action.split(': ')
				if (parts.length > 1) {
					const json = JSON.parse(parts[1])
					return parts[0] + "<br />" + JSON.stringify(json, null, 4)
				}
				return ""
			},

			closeLogsModal() {
				$("#logsModal").modal("hide")
			},

			async showLogs(id) {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				this.id = id
				this.loadingLogs = true
				$("#logsModal").modal("show")

				if (this.logsDataTable != null) {
					this.logsDataTable.destroy()
				}

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("page", this.logPage)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/invoices/logs",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.logs = response.data.logs
						this.logPages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loadingLogs = false

					setTimeout(function () {
						self.logsDataTable = jQuery("#logs-data").DataTable({
							ordering: false
						})
					}, 500)
				}
			},

			paginateLogs(page) {
				this.logPage = page
				this.showLogs(this.id)
			},

			dateFormat(date) {
				return date
				// date = new Date(date)
				// return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			getBank(bank) {
				return JSON.parse(bank)
			},

			async getData() {
				const self = this
				this.loading = true
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/invoices",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.invoices
						this.pages = response.data.pages
						this.banks = response.data.banks
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize() 
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#invoices-data").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#invoices-data").DataTable({
						ordering: false
					})
				}
			},

			async addInvoice() {
				const form = event.target
				const formData = new FormData(form)
				this.isAdding = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/invoices/add",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Add Invoice", response.data.message, response.data.status)

					if (response.data.status == "success") {
						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},

			getDueDate() {
				const date = new Date()
				date.setDate(date.getDate() + 3)

				return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
			}
		},

		mounted() {
			this.getData()
			document.title = "Invoices"

			jQuery(".dueDate").datetimepicker({
                format: "Y-m-d",
                timepicker: false,
                scrollMonth: false,
                value: this.getDueDate()
            })
		}
	}
</script>