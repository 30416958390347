<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Countries</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Countries</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">
						<button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddData" aria-expanded="false" aria-controls="collapseAddData">
						    Add Country
						</button>
                	</h3>

                	<div class="collapse" id="collapseAddData">
						<div class="card card-body">
							<form v-on:submit.prevent="addData" enctype="multipart/form-data">

            					<div class="form-group">
									<label class="form-label">Logo</label>
									<input type="file" name="logo" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Slides</label>
									<input type="file" multiple name="slides[]" class="form-control" />
								</div>

		                		<div class="form-group">
									<label class="form-label">Name</label>
									<input type="text" name="name" placeholder="Enter Name" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Region</label>
									<input type="text" name="region" placeholder="Enter Region" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Country Code</label>
									<input type="text" name="countryCode" placeholder="Enter Country Code" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Port</label>
									<input type="text" name="port" placeholder="Enter Port" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Shipping Line</label>
									<input type="text" name="shippingLine" placeholder="Enter Shipping Line" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Flag</label>
									<input type="file" name="flag" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Flag Big</label>
									<input type="file" name="flagBig" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Map</label>
									<input type="file" name="map" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Currency</label>
									<input type="text" name="currency" placeholder="Enter Currency" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Policy</label>
									<textarea name="policy" placeholder="Enter Policy" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">Intro</label>
									<textarea name="intro" placeholder="Enter Intro" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">Age Restriction</label>
									<textarea name="ageRestrictions" placeholder="Enter Age Restriction" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">Inspection</label>
									<textarea name="inspection" placeholder="Enter Inspection" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">Import Duties and Taxes</label>
									<textarea name="importDutiesTaxes" placeholder="Enter Import Duties and Taxes" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">TimeZone</label>
									<input type="text" name="timeZone" placeholder="Enter TimeZone" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Shipping Time</label>
									<input type="text" name="shippingTime" placeholder="Enter Shipping Time" class="form-control" />
								</div>

								<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
									Add Country

									<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
								</button>
		                	</form>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="row">
        				<div class="col-md-10">
        					<h3 class="box-title">All Countries</h3>
        				</div>

        				<div class="col-md-2 pull-right">
        					<button type="button" class="btn btn-info" style="color: white;" v-on:click="updateOrder">
        						Update Order
        					</button>
        				</div>
        			</div>

        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table class="table" id="data-table" v-if="!loading">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Name</th>
        							<th>Region</th>
        							<th>Currency</th>
        							<th>Time Zone</th>
        							<th>Shipping Time</th>
        							<th>Status</th>
        							<th>Actions</th> 
       							</tr>
        					</thead>

        					<tbody>
        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()" class="item" v-bind:id="d.id">
        								<td>
        									<table>
        										<tr>
        											<th>Name</th>
        											<td>
        												<a v-bind:href="$homeURL + '/country/' + d.name" target="_blank" v-text="d.name"></a>
        											</td>
        										</tr>
        										<tr>
        											<th>Region</th>
        											<td v-text="d.region"></td>
        										</tr>
        										<tr>
        											<th>Currency</th>
        											<td v-html="d.currency"></td>
        										</tr>
        										<tr>
        											<th>Time Zone</th>
        											<td v-text="d.timeZone"></td>
        										</tr>
        										<tr>
        											<th>Shipping Time</th>
        											<td v-text="d.shippingTime"></td>
        										</tr>
        										<tr>
        											<th>Status</th>
        											<td v-text="d.status"></td>
        										</tr>
        										<tr>
        											<th>Actions</th>
        											<td>
        												<router-link v-bind:to="'/countries/edit/' + d.id">Edit</router-link>

														<br />

														<a href="javascript:void(0)" v-on:click="showLogs(d.id)">Logs</a>

														<br />

														<button type="button" v-on:click="deleteData(d.id)" class="btn btn btn-danger text-white">Delete</button>
        											</td>
        										</tr>
        									</table>
        								</td>
        							</tr>

        							<tr v-else class="item" v-bind:id="d.id">
        								<td>
        									<a v-bind:href="$homeURL + '/country/' + d.name" target="_blank" v-text="d.name"></a>
        								</td>
        								<td v-text="d.region"></td>
        								<td v-html="d.currency"></td>
        								<td v-text="d.timeZone"></td>
        								<td v-text="d.shippingTime"></td>
        								<td v-text="d.status"></td>
										<td>
											<router-link v-bind:to="'/countries/edit/' + d.id">Edit</router-link>

											<br />

											<a href="javascript:void(0)" v-on:click="showLogs(d.id)">Logs</a>

											<br />

											<button type="button" v-on:click="deleteData(d.id)" class="btn btn btn-danger text-white">Delete</button>
										</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>

        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "CountriesComponent",

		data() {
			return {
				isAdding: false,
				dataTable: null,
				pageNumber: this.$route.query.page || 1,
				data: [],
				loading: false,
				sortedData: []
			}
		},

		methods: {
			deleteData(id) {
				const self = this
				const target = event.target

				swal.fire({
					title: 'Delete Country',
					text: "Are you sure you want to delete this country ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		target.setAttribute("disabled", "disabled")
		        		target.innerHTML = "Loading..."

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/countries/delete",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Delete Country", response.data.message, "success")
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
		        			target.innerHTML = "Delete Country"
						}
					}
				})
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},
			
			async getData() {
				const self = this
				this.loading = true
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize() 
					}, 500)
				}
			},

			initialize() {
				const self = this

				/*if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}*/

				$("#data-table").sortable({
		            "items": ".item",
		            "opacity": 0.6,
		            "cursor": "move",
		            "update": function (event, ui) {
		                var data = $("#data-table").sortable("toArray")
		                
		                // if using table
		                data = data.filter( n => n )
		                self.sortedData = data
		                self.updateOrder()
		            }
		        })
			},

			async addData() {
				const form = event.target
				const formData = new FormData(form)
				this.isAdding = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/add",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Add Country", response.data.message, response.data.status)

					if (response.data.status == "success") {
						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},

			async updateOrder() {
				document.querySelector(".loading").style.display = ""

				const formData = new FormData()
				formData.append("sortedData", JSON.stringify(this.sortedData))

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/updateOrder",
						formData,
						{
							headers: this.$headers
						}
					)
					// swal.fire("Update Order", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					document.querySelector(".loading").style.display = "none"
				}
			}
		},

		mounted() {
        	this.getData()
        	document.title = "Countries"

        	const obj = document.createElement("script")
            obj.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js")
            document.body.appendChild(obj)

            const obj2 = document.createElement("link")
            obj2.setAttribute("rel", "stylesheet")
            obj2.setAttribute("href", "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.css")
            document.body.appendChild(obj2)
        }
	}
</script>

<style scoped>
	.item {
		cursor: move;
	}
</style>