<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Transactions</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Transactions</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Transactions (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="table-data">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>User</th>
                                	<th>Type</th>
                                	<th>Payment Method</th>
                                	<th>Description</th>
                                	<th>Amount</th>
                                	<th>Banking Fee</th>
                                	<th>Shipping Charges</th>
                                	<th>Taxes</th>
                                	<th>Shipping Discount</th>
                                	<th>Created At</th>
                                	<th>Created By</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">User</th>
													<td>
														<router-link v-if="d.userName || d.userEmail" v-bind:to="'/users/edit/' + d.userId" v-text="d.userName + ' | ' + d.userEmail"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Type</th>
													<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>
												</tr>

												<tr>
													<th class="border-top-0">Payment Method</th>
													<td>
														<span v-text="d.paymentMethod" style="text-transform: capitalize;"></span>

														<span v-if="d.paymentMethod == 'bank'">
															(<span v-text="d.bank.accountTitle"></span>

																|

																<span v-text="d.bank.accountNumber"></span>)
														</span>

														<span v-if="d.paymentMethod == 'cheque'">
															(#<span v-text="d.cheque"></span>)
														</span>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Description</th>
													<td v-html="d.description"></td>
												</tr>

												<tr>
													<th class="border-top-0">Amount</th>
													<td v-text="d.currency + ' ' + d.amount"></td>
												</tr>

												<tr>
													<th class="border-top-0">Banking Fee</th>
													<td v-text="d.currency + ' ' + d.bankingFee"></td>
												</tr>

												<tr>
													<th class="border-top-0">Shipping Charges</th>
													<td v-text="d.currency + ' ' + d.shippingCharges"></td>
												</tr>

												<tr>
													<th class="border-top-0">Taxes</th>
													<td v-text="d.currency + ' ' + d.taxes"></td>
												</tr>

												<tr>
													<th class="border-top-0">Shipping Discount</th>
													<td v-text="d.currency + ' ' + d.shippingDiscount"></td>
												</tr>

												<tr>
													<th class="border-top-0">Created At</th>
													<td v-text="d.created_at"></td>
												</tr>

												<tr>
													<th class="border-top-0">Created By</th>
													<td>
														<router-link v-bind:to="'/users/edit/' + d.createdById" v-text="d.createdByName + ' | ' + d.createdByEmail"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<router-link v-bind:to="'/transactions/edit/' + d.id">
															Edit
														</router-link>

														<br />

														<button type="button" class="btn btn-primary" v-on:click="deleteTransaction(d.id)">
															Delete
														</button>

														<p style="margin-top: 10px;">
															<router-link v-bind:to="'/transactions/logs/' + d.id">
																Logs
															</router-link>
														</p>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
										<td>
											<router-link v-if="d.userName || d.userEmail" v-bind:to="'/users/edit/' + d.userId" v-text="d.userName + ' | ' + d.userEmail"></router-link>
										</td>

										<td style="text-transform: capitalize;" v-text="d.type.replace(/([a-z])([A-Z])/g, '$1 $2')"></td>

										<td>
											<span v-text="d.paymentMethod" style="text-transform: capitalize;"></span>

											<span v-if="d.paymentMethod == 'bank'">
												(<span v-text="d.bank.accountTitle"></span>

													|

													<span v-text="d.bank.accountNumber"></span>)
											</span>

											<span v-if="d.paymentMethod == 'cheque'">
												(#<span v-text="d.cheque"></span>)
											</span>
										</td>

										<td v-html="d.description"></td>

										<td v-text="d.currency + ' ' + d.amount"></td>

										<td v-text="d.currency + ' ' + d.bankingFee"></td>

										<td v-text="d.currency + ' ' + d.shippingCharges"></td>

										<td v-text="d.currency + ' ' + d.taxes"></td>

										<td v-text="d.currency + ' ' + d.shippingDiscount"></td>

										<td v-text="d.created_at"></td>

										<td>
											<router-link v-bind:to="'/users/edit/' + d.createdById" v-text="d.createdByName + ' | ' + d.createdByEmail"></router-link>
										</td>

										<td>
											<router-link v-bind:to="'/transactions/edit/' + d.id">
												Edit
											</router-link>

											<br />

											<button type="button" class="btn btn-primary" v-on:click="deleteTransaction(d.id)">
												Delete
											</button>

											<p style="margin-top: 10px;">
												<router-link v-bind:to="'/transactions/logs/' + d.id">
													Logs
												</router-link>
											</p>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "TransactionsComponent",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {

			deleteTransaction: function (id) {
				const self = this
				const node = event.target

				swal.fire({
					title: 'Delete Transaction ?',
					text: "Are you sure you want to delete this transaction ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {

					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		node.setAttribute("disabled", "disabled")

		        		try {
						    const response = await axios.post(
						        self.$apiURL + "/admin/transactions/destroy",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Transaction", response.data.message, "success")
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							node.removeAttribute("disabled")
						}
					}
				})
			},

			onKeyPressSearch: function() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

        	getData: async function () {
        		const self = this
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)
        		formData.append("timezone", timezone)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/transactions",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#table-data").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#table-data").DataTable({
		            	ordering: false
		            })
				}
	        }
        },

		mounted() {
        	this.getData()
        	document.title = "Transactions"
        }
	}
</script>