<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Parts</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Parts</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
            	<div class="white-box">
            		<h3 class="box-title">Add Part</h3>

            		<form v-on:submit.prevent="addPart" enctype="multipart/form-data">
            			<div class="form-group" style="margin-top: 20px;">
            				<label>Name</label>
            				<input type="text" name="name" class="form-control" />
            			</div>

						<div class="form-group">
							<label>Model</label>
							<input type="text" name="model" class="form-control" />
						</div>

						<div class="form-group">
							<label>Price</label>
							<input type="number" name="price" class="form-control" />
						</div>

						<div class="form-group">
							<label>Year</label>
							<input type="number" name="year" class="form-control" />
						</div>

						<div class="form-group">
							<label>Information</label>
							<input type="text" name="info" class="form-control" />
						</div>

						<div class="form-group">
							<label>Body</label>
							<input type="text" name="body" class="form-control" />
						</div>

						<div class="form-group">
							<label>Images</label>
							<input type="file" name="images[]" accept="image/*" multiple class="form-control" v-on:change="imagesSelected" />
						</div>

						<div class="row">
							<div class="col-md-2" v-for="(img, index) in images">
								<img v-bind:src="img" style="width: 100%;" />
							</div>
						</div>

						<button type="submit" class="btn btn-success" style="color: white; margin-top: 20px;" v-bind:disabled="addingPart">
							<span v-if="addingPart">Loading...</span>
							<span v-else>Add Part</span>
						</button>
            		</form>
            	</div>
            </div>
        </div>

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table v-if="!loading" class="table table-bordered" id="parts-data">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Info</th>
        							<th>In Stock</th>
        							<th>Images</th>
        							<th>Created By</th>
        							<th v-if="hasPermission('addPart')">Actions</th>
        						</tr>
        					</thead>

        					<tbody>

        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<th>
        									<table>
        										<tr>
        											<th>Info</th>
        											<td>
        												<span v-html="'<b>Name:</b> ' + d.name"></span><br />
			        									<span v-html="'<b>Model:</b> ' + d.model"></span><br />
			        									<span v-html="'<b>Year:</b> ' + d.year"></span><br />
			        									<span v-html="'<b>Price:</b> &yen;' + d.price"></span><br />
			        									<span v-html="'<b>Info:</b> ' + d.info"></span><br />
			        									<span v-html="'<b>Body:</b> ' + d.body"></span><br />
        											</td>
        										</tr>

        										<tr>
        											<th>In Stock</th>
        											<td v-text="d.inStock"></td>
        										</tr>

        										<tr>
        											<th>Images</th>
        											<td>
			        									<img v-for="(img, imgIndex) in d.images" v-bind:src="img" style="width: 100px; margin-left: 10px;" />
			        								</td>
        										</tr>

        										<tr>
        											<th>Created By</th>

        											<td>
			        									<template v-if="hasPermission('updateUsers')">

															<router-link v-bind:to="'/users/edit/' + d.createdBy" v-text="d.userName + ' | ' + d.userEmail">
																
															</router-link>

															<br />
														</template>

														<p v-else v-text="d.userName + ' | ' + d.userEmail"></p>
			        								</td>
        										</tr>

        										<tr v-if="hasPermission('addPart')">
        											<th>Actions</th>

        											<td v-if="hasPermission('addPart')">
														<router-link v-bind:to="'/parts/edit/' + d.id">
															Edit
														</router-link>

														<button type="button" class="btn btn-danger" v-on:click="deletePart(d.id)" style="color: white;">Delete</button>
			        								</td>
        										</tr>
        									</table>
        								</th>
        							</tr>

        							<tr v-else>
        								<td>
        									<span v-html="'<b>Name:</b> ' + d.name"></span><br />
        									<span v-html="'<b>Model:</b> ' + d.model"></span><br />
        									<span v-html="'<b>Year:</b> ' + d.year"></span><br />
        									<span v-html="'<b>Price:</b> &yen;' + d.price"></span><br />
        									<span v-html="'<b>Info:</b> ' + d.info"></span><br />
        									<span v-html="'<b>Body:</b> ' + d.body"></span><br />
        								</td>

        								<td v-text="d.inStock"></td>

        								<td>
        									<img v-for="(img, imgIndex) in d.images" v-bind:src="img" style="width: 300px; margin-left: 10px;" />
        								</td>

        								<td>
        									<template v-if="hasPermission('updateUsers')">

												<router-link v-bind:to="'/users/edit/' + d.createdBy" v-text="d.userName + ' | ' + d.userEmail">
													
												</router-link>

												<br />
											</template>

											<p v-else v-text="d.userName + ' | ' + d.userEmail"></p>
        								</td>

        								<td v-if="hasPermission('addPart')">
											<router-link v-bind:to="'/parts/edit/' + d.id">
												Edit
											</router-link>

											<button type="button" class="btn btn-danger" v-on:click="deletePart(d.id)" style="color: white;">Delete</button>
        								</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "PartsComponent",

		data() {
			return {
				addingPart: false,
				loading: false,
				data: [],
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null,
				search: "",
				images: []
			}
		},

		methods: {

			imagesSelected() {
				const self = this
				const files = event.target.files

				for (let a = 0; a < files.length; a++) {
					const fileReader = new FileReader()

					fileReader.onload = function (event) {
						self.images.push(event.target.result)
					}

					fileReader.readAsDataURL(files[a])
				}
			},

			deletePart: function (id) {
				const self = this

				swal.fire({
					title: 'Delete Part',
					text: "Are you sure you want to remove this part ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, remove it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/parts/delete",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Part Deleted", response.data.message, "success")

						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id == id) {
						    			self.data.splice(a, 1)
						    		}
						    	}
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							if (self.dataTable != null) {
				            	self.dataTable.destroy()
				            }

				            setTimeout(function () {
					    		self.initialize()
					    	}, 500)
						}
					}
				})
			},

			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			async addPart() {
				const self = this
				this.addingPart = true

				const form = event.target
				const formData = new FormData(form)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/parts/add",
						formData,
						{
							headers: this.$headers
						}
					)

					swal.fire("Add Part", response.data.message, response.data.status)

					if (response.data.status == "success") {
						this.data.push(response.data.part)

						if (this.dataTable != null) {
							this.dataTable.destroy()
						}

						setTimeout(function () {
							self.initialize()
						}, 500)

						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.addingPart = false
				}
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.pages = 0

				this.loading = true
				this.data = []

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("search", this.search)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/parts",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#parts-data").DataTable({
						ordering: false,
						pageLength: 1
					}) 
				} else {
					this.dataTable = jQuery("#parts-data").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Parts"
		}
	}
</script>