<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Add Transaction</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Add Transaction</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title" style="margin-bottom: 30px;">Add Transaction</h3>

                    <form v-on:submit.prevent="addTransaction">
                    	<div class="form-group">
                    		<label>Enter Email</label>
                    		<input type="email" name="email" class="form-control" />
                    	</div>

                    	<div class="form-group">
                    		<label>Currency</label>
                    		<input type="text" name="currency" v-model="currency" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>1 <span v-text="currency"></span> in USD</label>
                    		<input type="text" name="rateInUSD" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Type</label>
                    		<select name="type" class="form-control" required>
                    			<option value="securityDeposit">Security Deposit</option>
                    			<option value="carPayment">Car Payment</option>
                    			<option value="advanceCarPayment">Advance Car Payment</option>
                    		</select>
                    	</div>

                    	<div class="form-group">
                    		<label>Payment Method</label>
                    		<select name="paymentMethod" v-model="paymentMethod" class="form-control" required>
                    			<option value="bank">Bank</option>
                    			<option value="cash">Cash</option>
                    			<option value="cheque">Cheque</option>
                    			<option value="paypal">PayPal</option>
                    		</select>
                    	</div>

                    	<div class="form-group" v-if="paymentMethod == 'bank'">
                    		<label>Select Bank</label>
                    		<select name="bank" class="form-control">
                    			<option v-for="(b, index) in $banks" v-bind:value="JSON.stringify(b)" v-text="b.accountTitle + ' ' + b.accountNumber"></option>
                    		</select>
                    	</div>

                    	<div class="form-group" v-if="paymentMethod == 'cheque'">
                    		<label>Enter cheque number</label>
                    		<input type="text" name="cheque" class="form-control" />
                    	</div>

                    	<div class="form-group">
                    		<label>Amount</label>
                    		<input type="number" name="amount" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Description</label>
                    		<textarea name="description" class="form-control"></textarea>
                    	</div>

                    	<div class="form-group">
                    		<label>Banking Fee</label>
                    		<input type="number" name="bankingFee" value="0" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Shipping Charges</label>
                    		<input type="number" name="shippingCharges" value="0" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Taxes</label>
                    		<input type="number" name="taxes" value="0" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Shipping Discount</label>
                    		<input type="number" name="shippingDiscount" value="0" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>VAT</label>
                    		<input type="number" name="vat" value="0" class="form-control" required />
                    	</div>

                    	<button type="submit" class="btn btn-primary">
                    		Add Transaction

                    		<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
                    	</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"

	export default {
		name: "AddTransactionComponent",

		data() {
			return {
				isAdding: false,
				currency: "",
				paymentMethod: "bank"
			}
		},

		methods: {

        	addTransaction: async function () {
        		this.isAdding = true

        		const form = event.target
        		const formData = new FormData(form)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/transactions/store",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	swal.fire("Transaction", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},
        },

        mounted() {
        	document.title = "Add Transaction"
        }
	}
</script>