<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Edit Testimonial</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Edit Testimonial</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row">
            <div class="col-sm-12">
                <div class="white-box">
					<form v-on:submit.prevent="editTestimonial" v-if="testimonial != null">
                		<div class="form-group">
							<label class="form-label">Title</label>
							<input type="text" name="title" placeholder="Enter Title" class="form-control" v-model="testimonial.title" />
						</div>

						<div class="form-group">
							<label class="form-label">Content</label>
							<textarea type="text" name="content" placeholder="Enter Review" class="form-control" v-model="testimonial.content"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Name</label>
							<input type="text" name="name" placeholder="Enter User Name" class="form-control" v-model="testimonial.name" />
						</div>

						<div class="form-group">
							<label class="form-label">Stars</label>
							<input type="number" min="0" step="1" max="5" name="stars" placeholder="Enter Stars" class="form-control" v-model="testimonial.stars" />
						</div>

						<button type="submit" name="submit" class="btn btn-warning" style="color: black;">
							Edit Testimonial
							<i class="fa fa-spinner fa-spin" v-if="isUpdating"></i>
						</button>
                	</form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "EditTestimonialComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				testimonial: null,
				loading: false,
				isUpdating: false
			}
		},

		methods: {

			async editTestimonial() {
				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)
				this.isUpdating = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/testimonials/update",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Edit Testimonial", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isUpdating = false
				}
			},

			async getData() {
				const formData = new FormData()
				formData.append("id", this.id)
				this.loading = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/testimonials/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.testimonial = response.data.testimonial
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Edit Testimonial"
		}
	}
</script>