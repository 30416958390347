<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Car Orders</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="javascript:void(0)" class="fw-normal">Car Orders (<span v-text="total"></span>)</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>
				    <template v-else-if="car != null">

				    	<h3 class="box-title" v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME + ' - ' + car.YEAR"></h3>

	                    <div class="table-responsive" style="margin-top: 20px;">
	                    	<table v-if="!loading" class="table text-nowrap" id="data-table">
	                        	<thead>
	                                <tr v-if="$isMobile()">
	                                    <th></th>
	                                </tr>
	                                <tr v-else>
	                                	<th>User</th>
	                                	<th>Amount</th>
	                                	<th>Status</th>
	                                	<th>Actions</th>
	                                </tr>
	                            </thead>
	                            <tbody>
		                            <template v-for="(d, index) in data">
		                            	<tr v-if="$isMobile()">
		                            		<th>
		                            			<table>
		                            				<tr>
		                            					<th class="border-top-0">User</th>
		                            					<td>
															<router-link v-text="d.name + ' | ' + d.email" v-bind:to="'/users/edit/' + d.userId"></router-link>
														</td>
		                            				</tr>

		                            				<tr>
		                            					<th class="border-top-0">Amount</th>
		                            					<td v-html="d.currency + ' ' + d.amount"></td>
		                            				</tr>

		                            				<tr>
		                            					<th class="border-top-0">Status</th>
		                            					<td v-text="d.status"></td>
		                            				</tr>

		                            				<tr>
		                            					<th class="border-top-0">Action</th>
		                            					<router-link v-bind:to="'/chat/' + d.userId + '/' + car.awardedTo">View Chat</router-link>
		                            				</tr>
		                            			</table>
		                            		</th>
		                            	</tr>

			                            <tr v-else>
											<td>
												<router-link v-text="d.name + ' | ' + d.email" v-bind:to="'/users/edit/' + d.userId"></router-link>
											</td>
											<td v-html="d.currency + ' ' + d.amount"></td>
											<td v-text="d.status"></td>
											<td>
												<router-link v-bind:to="'/chat/' + d.userId + '/' + car.awardedTo">View Chat</router-link>
											</td>
										</tr>
		                            </template>
		                        </tbody>
		                    </table>
	                    </div>
	                </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"
    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "CarOrdersComponent",
		data() {
			return {
				id: this.$route.params.id || 0,
				data: [],
				loading: false,
				dataTable: null,
				total: 0,
				pages: 0,
				car: null,
				pageNumber: this.$route.query.page || 1
			}
		},
		methods: {
			changeStatus() {
				const self = this
				const select = event.target
				const form = select.parentElement
				const status = form.status.value
				let title = ""
				let confirmButtonText = ""
				if (status == "pending") {
					title = "This car will stop being displaying in 'featured' section ?"
					confirmButtonText = "Pending"
				} else if (status == "active") {
					title = "This car will start displaying in 'featured' section ?"
					confirmButtonText = "Activate"
				} else if (status == "sold") {
					title = "Are you sure you want to mark this car as sold ?"
					confirmButtonText = "Sold"
				}
				swal.fire({
					title: title,
					showCancelButton: true,
					confirmButtonText: confirmButtonText,
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData(form)
		        		try {
		        			// form.submit.setAttribute("disabled", "disabled")
		        			const response = await axios.post(
						        self.$apiURL + "/admin/investor/changeCarStatus",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						    swal.fire("Cars for Sale", response.data.message, response.data.status)
						    if (response.data.status == "success") {
						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].carForSaleId == form.id.value) {
						    			self.data[a].carForSaleStatus = status
						    		}
						    	}
						    }
		        		} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							// form.submit.removeAttribute("disabled")
						}
					}
				})
			},
			
			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			getData: async function () {
        		const self = this
        		this.pages = 0
        		this.loading = true
        		this.data = []
        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }
        		const formData = new FormData()
        		formData.append("id", this.id)
        		formData.append("page", this.pageNumber)
        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/investor/fetchCarOrders",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				    this.loading = false
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    	this.total = response.data.total
				    	this.car = response.data.car
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
		},
		mounted() {
			this.getData()
			document.title = "Car Orders"
		}
	}
</script>