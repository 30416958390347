<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">View Chat</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="javascript:void(0)" class="fw-normal">Total Messages (<span v-text="total"></span>)</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <div class="row" v-for="(d, index) in data" style="    background-color: #ececec;
					    border-radius: 10px;
					    padding: 20px;
					    margin-top: 20px;">
				    	<div class="col-md-12">
				    		<p style="font-size: 30px; margin-bottom: 0px;" v-text="d.senderName"></p>
				    		<p style="font-size: 14px; color: gray;" v-text="d.senderEmail"></p>
				    		<p v-text="d.message"></p>

				    		<p v-text="d.sendAt" style="margin-bottom: 0px;
							    color: gray;
							    font-size: 12px;"></p>
				    	</div>
				    </div>

				    <div class="text-center" v-if="loading" style="margin-top: 20px;">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

				    <div class="row" style="margin-top: 50px;">
				    	<div class="col-md-12">
				    		<nav aria-label="Page navigation example">
								<ul class="pagination">
									<li v-for="page in pages" v-bind:class="'page-item' + (page == pageNumber ? ' active' : '')">
										<a class="page-link" href="javascript:void(0)" v-text="page" v-on:click="paginate(page)"></a>
									</li>
								</ul>
							</nav>
				    	</div>
				    </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"
    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "ViewChatComponent",
		data() {
			return {
				user1: this.$route.params.user1 || 0,
				user2: this.$route.params.user2 || 0,
				user1Obj: null,
				user2Obj: null,
				total: 0,
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},
		methods: {
			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async decryptMessage(message, iv, privateKey, publicKey) {
                try {
                    iv = new Uint8Array(atob(iv).split("").map(function(c) {
                        return c.charCodeAt(0)
                    }))
                    const initializationVector = new Uint8Array(iv).buffer
                    const string = atob(message)
                    const uintArray = new Uint8Array(
                        [...string].map((char) => char.charCodeAt(0))
                    )
                    const derivedKey = await this.getDerivedKey(privateKey, publicKey)
                    const decryptedData = await window.crypto.subtle.decrypt(
                        {
                            name: "AES-GCM",
                            iv: initializationVector,
                        },
                        derivedKey,
                        uintArray
                    )
                    return new TextDecoder().decode(decryptedData)
                } catch (e) {
                    // return `error decrypting message: ${e}`
                    return ""
                }
            },
            async getDerivedKey(privateKey, publicKey) {
                try {
                    const publicKeyObj = await window.crypto.subtle.importKey(
                        "jwk",
                        publicKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        []
                    )
                    const privateKeyObj = await window.crypto.subtle.importKey(
                        "jwk",
                        privateKey,
                        {
                            name: "ECDH",
                            namedCurve: "P-256",
                        },
                        true,
                        ["deriveKey", "deriveBits"]
                    )
                    const derivedKey = await window.crypto.subtle.deriveKey(
                        { name: "ECDH", public: publicKeyObj },
                        privateKeyObj,
                        { name: "AES-GCM", length: 256 },
                        true,
                        ["encrypt", "decrypt"]
                    )
                    return derivedKey
                } catch (e) {
                    return null
                }
            },

			getData: async function () {
        		const self = this
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        		this.loading = true
        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }
        		const formData = new FormData()
        		formData.append("user1", this.user1)
        		formData.append("user2", this.user2)
        		formData.append("page", this.pageNumber)
        		formData.append("timezone", timezone)
        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/fetchChat",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				    this.loading = false
				    if (response.data.status == "success") {
				    	// this.data = response.data.data
				    	this.pages = response.data.pages
				    	this.total = response.data.total
				    	this.user1Obj = response.data.user1Obj
				    	this.user2Obj = response.data.user2Obj

				    	const chats = response.data.data
                        for (let a = 0; a < chats.length; a++) {
                            chats[a].message = await this.decryptMessage(chats[a].message, chats[a].iv, chats[a].privateKey, chats[a].publicKey)
                        }
                        this.data = chats
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
		            	ordering: false
		            })
				}
	        }
		},
		mounted() {
			this.getData()
			document.title = "Chat"
		}
	}
</script>