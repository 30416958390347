<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Payees</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Payees</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">
						<button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddPayee" aria-expanded="false" aria-controls="collapseAddPayee">
						    Add Payee
						</button>
                	</h3>

                	<div class="collapse" id="collapseAddPayee">
						<div class="card card-body">
							<form v-on:submit.prevent="addPayee">

		                		<div class="form-group">
									<label class="form-label">Name</label>
									<input type="text" name="name" placeholder="Enter Bank Name" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Account Number</label>
									<input type="text" name="accountNumber" placeholder="Enter Account Number" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Account Title</label>
									<input type="text" name="accountTitle" placeholder="Enter Account Title" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Swift Code</label>
									<input type="text" name="swiftCode" placeholder="Enter Swift Code" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Branch</label>
									<input type="text" name="branch" placeholder="Enter Branch" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Address</label>
									<input type="text" name="address" placeholder="Enter Address" class="form-control" />
								</div>

								<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
									Add Payee

									<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
								</button>
		                	</form>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<h3 class="box-title">All Payees</h3>

        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table class="table" id="data-table" v-if="!loading">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Name</th>
        							<th>Account Number</th>
        							<th>Account Title</th>
        							<th>Swift Code</th>
        							<th>Branch</th>
        							<th>Address</th>
        							<th>Actions</th>
        						</tr>
        					</thead>

        					<tbody>
        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<td>
        									<table>
        										<tr>
        											<th>Name</th>
        											<td v-text="d.name"></td>
        										</tr>

        										<tr>
        											<th>Account Number</th>
        											<td v-text="d.accountNumber"></td>
        										</tr>

        										<tr>
        											<th>Account Title</th>
        											<td v-text="d.accountTitle"></td>
        										</tr>

        										<tr>
        											<th>Swift Code</th>
        											<td v-text="d.swiftCode"></td>
        										</tr>

        										<tr>
        											<th>Branch</th>
        											<td v-text="d.branch"></td>
        										</tr>

        										<tr>
        											<th>Actions</th>
        											<td>
														<router-link v-bind:to="'/payees/edit/' + d.id">Edit</router-link>

														<br />

														<button type="button" v-on:click="deletePayee(d.id)" class="btn btn btn-danger text-white">Delete</button>
													</td>
        										</tr>
        									</table>
        								</td>
        							</tr>

        							<tr v-else>
        								<td v-text="d.name"></td>
        								<td v-text="d.accountNumber"></td>
        								<td v-text="d.accountTitle"></td>
        								<td v-text="d.swiftCode"></td>
        								<td v-text="d.branch"></td>
        								<td v-text="d.address"></td>
										<td>
											<router-link v-bind:to="'/payees/edit/' + d.id">Edit</router-link>

											<br />

											<button type="button" v-on:click="deletePayee(d.id)" class="btn btn btn-danger text-white">Delete</button>
										</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>

        		</div>
        	</div>
        </div>

    </div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"
    import jQuery from "jquery"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "PayeesComponent",

		data() {
			return {
				isAdding: false,
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {
			deletePayee(id) {
				const self = this
				const target = event.target

				swal.fire({
					title: 'Delete Payee',
					text: "Are you sure you want to delete this payee ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		target.setAttribute("disabled", "disabled")
		        		target.innerHTML = "Loading..."

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/payees/destroy",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Delete Payee", response.data.message, "success")
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
		        			target.innerHTML = "Delete"
						}
					}
				})
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.loading = true

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/payees",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize() 
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}
			},

			async addPayee() {
				const form = event.target
				const formData = new FormData(form)
				this.isAdding = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/payees/add",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Add Payee", response.data.message, response.data.status)

					if (response.data.status == "success") {
						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Payees"
		}
	}
</script>