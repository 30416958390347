<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Users Management</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Users Management</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">User</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

				    <br />

				    <form v-on:submit.prevent="editUser" v-if="user != null">
				    	<div class="form-group mb-4">
                            <label class="col-md-12 p-0">Name</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" name="name" v-model="user.name" class="form-control p-0 border-0" readonly />
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Email</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="email" name="email" v-model="user.email" class="form-control p-0 border-0" readonly />
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Bid Limit</label>
                            <div class="col-md-12 border-bottom p-0">
                            	<select name="bidLimit" v-model="user.bidLimit" class="form-control p-0 border-0">
                            		<option value="0">0</option>
                                    <option value="1000000">1 million</option>
                                    <option value="1500000">1.5 million</option>
                                    <option value="2000000">2 million</option>
                                    <option value="3000000">3 million</option>
                                    <option value="4000000">4 million</option>
                                    <option value="5000000">5 million</option>
                                    <option value="unlimited">Unlimited</option>
                            	</select>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Permissions</label>
                            <div class="col-md-12 border-bottom p-0">

		                        <p>
                            		<label class="custom-control custom-checkbox">
	                                    <input type="checkbox" name="isPaid" v-bind:checked="user.isPaid" />&nbsp;
	                                    <span class="custom-control-label">Auctions Access</span>

	                                    <p v-if="user.paidBy != 0">
	                                    	Paid by: <span v-text="user.paidBy.name + ' | ' + user.paidBy.email"></span>
	                                    </p>
	                                </label>
                            	</p>

                            	<p v-for="(p, index) in permissions">
                            		<label class="custom-control custom-checkbox">
	                                    <input type="checkbox" class="custom-control-input" name="permissions[]" v-bind:value="p" v-bind:checked="hasPermission(p)" />&nbsp;
	                                    <span class="custom-control-label" style="text-transform: capitalize;" v-text="p.replace(/([a-z])([A-Z])/g, '$1 $2')"></span>

	                                    <p v-if="hasPermission(p)">
	                                    	Given by: <span v-text="hasPermission(p).name + ' | ' + hasPermission(p).email"></span>
	                                    </p>
	                                </label>
                            	</p>

                            </div>
                        </div>

                        <div v-if="loggedInUser != null && loggedInUser.role == 'developer'" class="form-group mb-4">
                        	<label class="col-md-12 p-0">Role</label>
                            <div class="col-md-12 border-bottom p-0">
                            	<select class="form-control" name="role" v-model="user.role">
                            		<option value="user">User</option>
                            		<option value="admin">Admin</option>
                            	</select>
                            </div>
                        </div>

                        <div class="form-group mb-4">
                            <div class="col-sm-12">
                                <button type="submit" class="btn btn-success" style="color: white;" v-bind:disabled="updating">
                                	<span class="spinner-border spinner-border-sm" v-if="updating"></span>
                                	Update User
                                </button>
                            </div>
                        </div>
				    </form>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"

	export default {
		name: "EditUserComponent",

		computed: {
			loggedInUser() {
				return store.getters.getUser
			}
		},

		data() {
			return {
				id: this.$route.params.id || 0,
				user: null,
				loading: false,
				updating: false,
				permissions: ["viewBids", "uploadRequestedImages", "viewImageRequests", "translateAuctionSheet", "viewAuctionSheets", "viewAwardedCars", "updateStatus", "updateUsers", "viewUsers", "awardBid", "commentOnBid", "transactions", "addTransaction"]
			}
		},

		methods: {

			hasPermission: function (action) {
				for (let a = 0; a < this.user.permissions.length; a++) {
					if (this.user.permissions[a].action == action) {
						return this.user.permissions[a]
					}
				}
				return null
			},

			editUser: async function () {
				const form = event.target
        		this.updating = true

        		const formData = new FormData(form)
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/users/update",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	swal.fire("Update User", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.updating = false
				}
			},

        	getData: async function () {
        		this.loading = true

        		const formData = new FormData()
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/users/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.user = response.data.user
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
        },

		mounted() {
        	this.getData()
        	document.title = "Edit User"
        }
	}
</script>