<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Pages</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Pages</a></li>
                        <li><a href="#" class="fw-normal">Edit</a></li>
                        <li><a href="#" class="fw-normal" v-text="id"></a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
            	<div class="white-box">
            		<h3 class="box-title">Edit Page</h3>

            		<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

            		<form v-on:submit.prevent="editPage" v-if="page != null">
            			<div class="form-group" style="margin-top: 20px;">
            				<label>Name</label>
            				<input type="text" v-model="page.name" name="name" class="form-control" />
            			</div>
						<div class="form-group">
							<label>Title</label>
							<input type="text" v-model="page.title" name="title" class="form-control" />
						</div>
						<div class="form-group">
							<label>Content</label>
							<textarea name="content" class="form-control" v-model="page.content" rows="7"></textarea>
						</div>
						<div class="form-group">
							<label>Status</label>
							<select v-model="page.status" name="status" class="form-control">
								<option value="active">Active</option>
								<option value="draft">Draft</option>
								<option value="trash">Trash</option>
							</select>
						</div>
						<button type="submit" class="btn btn-warning" style="color: black; margin-top: 20px;" v-bind:disabled="editing">
							<span v-if="editing">Loading...</span>
							<span v-else>Edit Page</span>
						</button>
            		</form>
            	</div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "EditPageComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				loading: false,
				page: null,
				editing: false
			}
		},

		methods: {
			async editPage() {
				this.editing = true
				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)
				try {
					const response = await axios.post(
						this.$apiURL + "/admin/pages/update",
						formData,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						swal.fire("Edit Page", response.data.message, "success")
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.editing = false
				}
			},

			async getData() {
				this.loading = true
				const formData = new FormData()
				formData.append("page", this.id)
				try {
					const response = await axios.post(
						this.$apiURL + "/admin/pages/fetch",
						formData,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						this.page = response.data.page
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Edit Page"
		}
	}
</script>