<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Tracking</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Tracking</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Tracking</h3>

                    <button type="button" class="btn btn-primary" v-on:click="showAddTrackingModal" style="margin-bottom: 20px;">
                    	Add Tracking Log
					</button>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table class="table text-nowrap" id="table-data">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>Log</th>
                                	<th>Attachment</th>
                                	<th>Date and Time</th>
                                	<th>Logged By</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data" v-bind:key="index">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">Log</th>
													<td v-text="d.log"></td>
												</tr>

												<tr>
													<th class="border-top-0">Attachments</th>
													<template v-for="(attachment, attachmentIndex) in d.attachments">
														<a v-bind:href="attachment" v-text="attachment.split('/')[attachment.split('/').length - 1]" target="_blank"></a>
													</template>
												</tr>

												<tr>
													<th class="border-top-0">Date &amp; Time</th>
													<td v-html="d.created_at"></td>
												</tr>

												<tr>
													<th class="border-top-0">Created By</th>
													<td>
														<router-link v-text="d.createdByName + ' | ' + d.createdByEmail" v-bind:to="'/users/edit/' + d.createdById"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<button type="button" class="btn btn-primary" v-on:click="deleteTrackingLog(d.id)">
															Delete
														</button>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
										<td v-text="d.log"></td>

										<td>
											<template v-for="(attachment, attachmentIndex) in d.attachments">
												<a v-bind:href="attachment" v-text="attachment.split('/')[attachment.split('/').length - 1]" target="_blank"></a>
											</template>
										</td>

										<td v-html="d.created_at"></td>

										<td>
											<router-link v-text="d.createdByName + ' | ' + d.createdByEmail" v-bind:to="'/users/edit/' + d.createdById"></router-link>
										</td>

										<td>
											<button type="button" class="btn btn-primary" v-on:click="deleteTrackingLog(d.id)">
												Delete
											</button>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>

    <!-- The Modal -->
	<div ref="addTrackingModal" class="modal">

		<!-- Modal content -->
		<div class="modal-content">
			<span class="close" v-on:click="closeAddTrackingModal">&times;</span>

			<h3>Add Tracking Log</h3>

			<form v-on:submit.prevent="addTracking" enctype="multipart/form-data">
				<div class="form-group">
					<label>Enter Log Message</label>
					<textarea name="log" v-model="log" class="form-control" required></textarea>
				</div>

				<div class="form-group">
					<label>Attachments (optional)</label>
					<input type="file" multiple name="attachments[]" class="form-control" />
				</div>

				<div class="form-group">
					<label>Send SMS
						<input type="checkbox" name="sendSms" value="yes" v-model="sendSms" v-on:change="onchangeSmsCheckbox" />
					</label>
				</div>

				<div v-if="sendSms" class="form-group">
					<label>SMS</label>
					<textarea name="smsValue" v-model="smsValue" class="form-control" rows="7"></textarea>
				</div>

				<input type="submit" name="submit" class="btn btn-primary" value="Add Log" />
			</form>
		</div>

	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "TrackingComponent",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				id: this.$route.params.id || 0,
				sendSms: false,
				smsValue: "",
				log: ""
			}
		},

		methods: {

			onchangeSmsCheckbox() {
				this.smsValue = this.smsValue.replace("{{ shipmentStatus }}", this.log)
			},

			addTracking: async function () {
				const self = this

				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)
				formData.append("sendSms", this.sendSms)
				formData.append("smsValue", this.smsValue)

        		form.submit.setAttribute("disabled", "disabled")

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/tracking/store",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	// swal.fire("Add Tracking", response.data.message, "success")
				    	form.reset()
				    	this.data.unshift(response.data.obj)
				    	this.closeAddTrackingModal()

						setTimeout(function () {
				    		self.initialize()
				    	}, 500)
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {					
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					form.submit.removeAttribute("disabled")
				}
			},

			deleteTrackingLog: function (id) {
				const self = this
				const node = event.target

				swal.fire({
					title: 'Delete Tracking Log ?',
					text: "Are you sure you want to delete this tracking log ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {

					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		node.setAttribute("disabled", "disabled")

		        		try {
						    const response = await axios.post(
						        self.$apiURL + "/admin/tracking/destroy",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	const data = []
						    	for (let a = 0; a < self.data.length; a++) {
						    		if (self.data[a].id != id) {
						    			data.push(self.data[a])
						    		}
						    	}
						    	self.data = data

					    		setTimeout(function () {
					    			self.initialize()
					    		}, 500)
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							node.removeAttribute("disabled")
						}
					}
				})
			},

			onKeyPressSearch: function() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

        	getData: async function () {
        		const self = this
        		this.pages = 0
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

        		this.loading = true
        		this.data = []

        		const formData = new FormData()
        		formData.append("id", this.id)
        		formData.append("page", this.pageNumber)
        		formData.append("timezone", timezone)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/tracking/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    	const user = response.data.user
				    	const configurations = response.data.configurations
				    	for (let a = 0; a < configurations.length; a++) {
				    		if (configurations[a].key == "smsShipmentTracking") {
				    			this.smsValue = configurations[a].value
				    			break
				    		}
				    	}

				    	if (user != null) {
				    		this.smsValue = this.smsValue.replace("{{ user }}", user.name)
				    	}
				    	
						setTimeout(function () {
				    		self.initialize()
				    	}, 500)
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			},

			initialize() {
				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				if (this.$isMobile()) {
					this.dataTable = jQuery("#table-data").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					// this.dataTable = jQuery("#table-data").DataTable({
		            // 	ordering: false
		            // })
				}
	        },

	        showAddTrackingModal: function () {
	        	this.$refs["addTrackingModal"].style.display = "block"
	        },

	        closeAddTrackingModal: function () {
	        	this.$refs["addTrackingModal"].style.display = "none"
	        }
        },

		mounted() {
			const self = this
        	this.getData()
        	document.title = "Tracking"

			// When the user clicks anywhere outside of the modal, close it
			setTimeout(function () {
				window.onclick = function(event) {
					if (event.target == self.$refs["addTrackingModal"]) {
						self.$refs["addTrackingModal"].style.display = "none"
					}
				}
			}, 500)
        }
	}
</script>

<style scoped>
	/* The Modal (background) */
	.modal {
	  display: none; /* Hidden by default */
	  position: fixed; /* Stay in place */
	  z-index: 100; /* Sit on top */
	  left: 0;
	  top: 0;
	  width: 100%; /* Full width */
	  height: 100%; /* Full height */
	  overflow: auto; /* Enable scroll if needed */
	  background-color: rgb(0,0,0); /* Fallback color */
	  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
	}

	/* Modal Content/Box */
	.modal-content {
	  background-color: #fefefe;
	  margin: 15% auto; /* 15% from the top and centered */
	  padding: 20px;
	  border: 1px solid #888;
	  width: 80%; /* Could be more or less, depending on screen size */
	}

	/* The Close Button */
	.close {
	  color: #aaa;
	  float: right;
	  font-size: 28px;
	  font-weight: bold;
	  text-align: right;
	}

	.close:hover,
	.close:focus {
	  color: black;
	  text-decoration: none;
	  cursor: pointer;
	}
</style>