<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Parts Management</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Parts Management</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Edit Part</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

				    <br />

				    <form v-on:submit.prevent="editPart" v-if="part != null" enctype="multipart/form-data">
				    	<div class="form-group" style="margin-top: 20px;">
            				<label>Name</label>
            				<input type="text" name="name" v-model="part.name" class="form-control" />
            			</div>

						<div class="form-group">
							<label>Model</label>
							<input type="text" name="model" v-model="part.model" class="form-control" />
						</div>

						<div class="form-group">
							<label>Price</label>
							<input type="number" name="price" v-model="part.price" class="form-control" />
						</div>

						<div class="form-group">
							<label>Year</label>
							<input type="number" name="year" v-model="part.year" class="form-control" />
						</div>

						<div class="form-group">
							<label>Information</label>
							<input type="text" name="info" v-model="part.info" class="form-control" />
						</div>

						<div class="form-group">
							<label>Body</label>
							<input type="text" name="body" v-model="part.body" class="form-control" />
						</div>

						<div class="form-group">
							<label>Images</label>
							<input type="file" name="images[]" accept="image/*" multiple class="form-control" v-on:change="imagesSelected" />
						</div>

						<div class="row">
							<div class="col-md-2" v-for="(img, index) in part.images">
								<img v-bind:src="img" style="width: 100%;" />
							</div>
						</div>
				    	
                        <div class="row" style="margin-top: 15px;">
                            <div class="col-sm-12">
                                <button type="submit" class="btn btn-success" style="color: white;" v-bind:disabled="updating">
                                	<span class="spinner-border spinner-border-sm" v-if="updating"></span>
                                	Update Part
                                </button>
                            </div>
                        </div>
				    </form>

				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"

	export default {
		name: "EditPartComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				part: null,
				loading: false,
				updating: false
			}
		},

		methods: {

			imagesSelected() {
				if (this.part == null) {
					return
				}

				const self = this
				const files = event.target.files
				this.part.images = []

				for (let a = 0; a < files.length; a++) {
					const fileReader = new FileReader()

					fileReader.onload = function (event) {
						self.part.images.push(event.target.result)
					}

					fileReader.readAsDataURL(files[a])
				}
			},

			editPart: async function () {
				const form = event.target
        		this.updating = true

        		const formData = new FormData(form)
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/parts/update",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	swal.fire("Update Part", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.updating = false
				}
			},

        	getData: async function () {
        		this.loading = true

        		const formData = new FormData()
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/parts/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.part = response.data.part
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
        },

		mounted() {
        	this.getData()
        	document.title = "Edit Part"
        }
	}
</script>