<template>
  <!-- ============================================================== -->
  <!-- Main wrapper - style you can find in pages.scss -->
  <!-- ============================================================== -->

  <router-view v-if="$route.path.includes('mails/fetch')" />

  <div v-else id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
      data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
    <app-header />
    <div class="page-wrapper">

      <router-view v-if="user != null && (user.role == 'admin' || user.role == 'superAdmin' || user.role == 'developer')" />

      <router-view v-else-if="$route.path == '/login'" />

      <div class="text-center" v-if="loading" style="padding: 100px;">
        <div class="spinner-grow"></div>
      </div>

      <app-footer />
    </div>
  </div>
</template>

<script>

  import AppHeader from "./components/layouts/AppHeader.vue"
  import AppFooter from "./components/layouts/AppFooter.vue"

  import store from "./vuex/store"

  export default {
    name: 'App',

    components: {
      AppHeader,
      AppFooter
    },

    computed: {
      loading() {
        return store.getters.getLoading
      },

      user() {
        return store.getters.getUser
      }
    }
  }

  document.title = "USSGlobal.com"
</script>
