<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">User Tracking</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">User Tracking</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">User Tracking</h3>

                    <div class="text-center" v-if="loading">
                    	<div class="spinner-border"></div>
                    </div>

                    <div class="table-responsive">
                    	<table v-if="!loading" class="table" id="data">
                    		<thead>
                    			<tr v-if="$isMobile()">
                    				<th></th>
                    			</tr>

                    			<tr v-else>
                    				<th>User</th>
                    				<th>IP</th>
                    				<th>Device</th>
                    				<th>From</th>
                    				<th>To</th>
                    				<th>Date &amp; Time</th>
                    			</tr>
                    		</thead>

                    		<tbody>
                    			<template v-for="(d, index) in data" v-bind:key="d.id">
	                    			<tr v-if="$isMobile()">
	                    				<td>
	                    					<table>
	                    						<tr>
	                    							<th>User</th>
	                    							<td>
				                    					<span v-text="d.name"></span>
				                    					<br />
				                    					<span v-text="d.email"></span>
				                    				</td>
	                    						</tr>

	                    						<tr>
	                    							<th>IP</th>
	                    							<td>
				                    					<span v-text="d.ip"></span>
				                    					<br />
				                    					<span v-text="d.loc"></span>
				                    				</td>
	                    						</tr>

	                    						<tr>
	                    							<th>Device</th>
	                    							<td v-text="d.uag"></td>
	                    						</tr>

	                    						<tr>
	                    							<th>From</th>
	                    							<td v-text="d.from"></td>
	                    						</tr>

	                    						<tr>
	                    							<th>To</th>
	                    							<td v-text="d.to"></td>
	                    						</tr>

	                    						<tr>
	                    							<th>Date &amp; Time</th>
	                    							<td v-text="d.created_at"></td>
	                    						</tr>
	                    					</table>
	                    				</td>
	                    			</tr>

	                    			<tr v-else>
	                    				<td>
	                    					<span v-text="d.name"></span>
	                    					<br />
	                    					<span v-text="d.email"></span>
	                    				</td>

	                    				<td>
	                    					<span v-text="d.ip"></span>
	                    					<br />
	                    					<span v-text="d.loc"></span>
	                    				</td>
	                    				<td v-text="d.uag"></td>
	                    				<td v-text="d.from"></td>
	                    				<td v-text="d.to"></td>
	                    				<td v-text="d.created_at"></td>
	                    			</tr>
	                    		</template>
                    		</tbody>
                    	</table>
                    </div>

                    <div v-if="pages > 0">
                    	<nav>
                    		<ul class="pagination">
                    			<li v-for="page in pages" v-bind:key="page" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
                    				<a href="javascript:void(0)" class="page-link" v-text="page"
                    					v-on:click="paginate(page)"></a>
                    			</li>
                    		</ul>
                    	</nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "UserTrackingComponent",

		data() {
			return {
				loading: false,
				data: [],
				search: "",
				pageNumber: this.$route.query.page || 1,
				pages: 0,
				dataTable: null
			}
		},

		methods: {

			paginate(page) {
				this.pageNumber = page
				this.getData()
				this.addOrUpdateURLParam("page", page)
			},

			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			async getData() {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				this.loading = true

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("search", this.search)
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/users/tracking",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "User Tracking"
		}
	}
</script>