<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Edit Country</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Edit Country</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<div v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

					<form v-on:submit.prevent="updateData" enctype="multipart/form-data" v-if="data != null">
						<div class="form-group">
							<label class="form-label">Logo</label>
							<input type="file" name="logo" ref="logo" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.logo">
								<div class="col-md-3">
									<img v-bind:src="data.logo" style="width: 100%; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.logo, 'logo')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Slides</label>
							<input type="file" multiple name="slides[]" placeholder="Select Slides" class="form-control" ref="slides" />

							<div class="row" style="margin-top: 10px;">
								<div class="col-md-3" v-for="(slide, index) in data.slides">
									<img v-bind:src="slide" style="width: 100%; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteSlide(slide)" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

                		<div class="form-group">
							<label class="form-label">Name</label>
							<input type="text" name="name" placeholder="Enter Name" class="form-control" v-model="data.name" />
						</div>

						<div class="form-group">
							<label class="form-label">Region</label>
							<input type="text" name="region" placeholder="Enter Region" class="form-control" v-model="data.region" />
						</div>

						<div class="form-group">
							<label class="form-label">Country Code</label>
							<input type="text" name="countryCode" placeholder="Enter Country Code" class="form-control" v-model="data.countryCode" />
						</div>

						<div class="form-group">
							<label class="form-label">Port</label>
							<input type="text" name="port" placeholder="Enter Port" class="form-control" v-model="data.port" />
						</div>

						<div class="form-group">
							<label class="form-label">Shipping Line</label>
							<input type="text" name="shippingLine" placeholder="Enter Shipping Line" class="form-control" v-model="data.shippingLine" />
						</div>

						<div class="form-group">
							<label class="form-label">Flag</label>
							<input type="file" name="flag" ref="flag" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.flag">
								<div class="col-md-3">
									<img v-bind:src="data.flag" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.flag, 'flag')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Flag Big</label>
							<input type="file" name="flagBig" ref="flagBig" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.flagBig">
								<div class="col-md-3">
									<img v-bind:src="data.flagBig" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.flagBig, 'flagBig')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Map</label>
							<input type="file" name="map" ref="map" class="form-control" />

							<div class="row" style="margin-top: 10px;" v-if="data.map">
								<div class="col-md-3">
									<img v-bind:src="data.map" style="width: 300px; display: block; margin-bottom: 10px;" />
									<button type="button" class="btn btn-danger btn-sm" v-on:click="deleteFile(data.map, 'map')" style="color: white;">
										Delete
										<i class="fa fa-spinner fa-spin" style="display: none;"></i>
									</button>
								</div>
							</div>
						</div>

						<div class="form-group">
							<label class="form-label">Currency</label>
							<input type="text" name="currency" placeholder="Enter Currency" class="form-control" v-model="data.currency" />
						</div>

						<div class="form-group">
							<label class="form-label">Policy</label>
							<textarea name="policy" placeholder="Enter Policy" class="form-control" v-model="data.policy"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Intro</label>
							<textarea name="intro" placeholder="Enter Intro" class="form-control" v-model="data.intro"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Age Restriction</label>
							<textarea name="ageRestrictions" placeholder="Enter Age Restriction" class="form-control" v-model="data.ageRestrictions"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Inspection</label>
							<textarea name="inspection" placeholder="Enter Inspection" class="form-control" v-model="data.inspection"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Import Duties and Taxes</label>
							<textarea name="importDutiesTaxes" placeholder="Enter Import Duties and Taxes" class="form-control" v-model="data.importDutiesTaxes"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">TimeZone</label>
							<input type="text" name="timeZone" placeholder="Enter TimeZone" class="form-control" v-model="data.timeZone" />
						</div>

						<div class="form-group">
							<label class="form-label">Shipping Time</label>
							<input type="text" name="shippingTime" placeholder="Enter Shipping Time" class="form-control" v-model="data.shippingTime" />
						</div>

						<div class="form-group">
							<label class="form-label">Status</label>
							<select name="status" class="form-control" v-model="data.status">
								<option value="active">Active</option>
								<option value="inactive">De-activate</option>
							</select>
						</div>

						<button type="submit" name="submit" class="btn btn-warning" style="color: black;">
							Edit Country
							<i class="fa fa-spinner fa-spin" v-if="isEditing"></i>
						</button>
                	</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import axios from "axios"
	import swal from "sweetalert2"

	export default {
		name: "EditCountryComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				data: null,
				loading: false,
				isEditing: false
			}
		},

		methods: {
			async deleteFile(filePath, type) {
				const target = event.target
				target.setAttribute("disabled", "disabled")
				target.querySelector("i").style.display = ""

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("filePath", filePath)
				formData.append("type", type)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/deleteFile",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data[type] = ""
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
					target.querySelector("i").style.display = "none"
				}
			},

			async deleteSlide(slide) {
				const target = event.target
				target.setAttribute("disabled", "disabled")
				target.querySelector("i").style.display = ""

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("slide", slide)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/deleteSlide",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						for (let a = 0; a < this.data.slides.length; a++) {
							if (this.data.slides[a] === response.data.slide) {
								this.data.slides.splice(a, 1)
							}
						}
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.removeAttribute("disabled")
					target.querySelector("i").style.display = "none"
				}
			},

			async updateData() {
				this.isEditing = true

				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/update",
						formData,
						{
							headers: this.$headers
						}
					)

					swal.fire("Edit Country", response.data.message, response.data.status)

					if (response.data.status == "success") {
						this.$refs["logo"].value = ""
						this.$refs["slides"].value = ""
						this.$refs["flag"].value = ""
						this.$refs["flagBig"].value = ""
						this.$refs["map"].value = ""
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isEditing = false
				}
			},

			async getData() {
				const self = this
				this.loading = true

				const formData = new FormData()
				formData.append("id", this.id)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/countries/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
        	this.getData()
        	document.title = "Edit Country"
        }
	}
</script>