<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Payments</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Payments</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table v-if="!loading" class="table table-bordered" id="orders-data">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>User</th>
        							<th>Method</th>
        							<th>Price</th>
        							<th>Date &amp; Time</th>
        						</tr>
        					</thead>

        					<tbody>

        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<th>
        									<table>
        										<tr>
        											<th>User</th>
        											<td>
        												<span v-html="'<b>Name:</b> ' + d.name"></span><br />
			        									<span v-html="'<b>Email:</b> ' + d.email"></span><br />
			        									<span v-html="'<b>Mobile:</b> ' + d.mobile"></span><br />
			        									<span v-html="'<b>Address:</b> ' + d.address"></span><br />
			        									<span v-html="'<b>Country Code:</b> ' + d.countryCode"></span>
        											</td>
        										</tr>

        										<tr>
        											<th>Method</th>
        											<td>
        												<a v-if="d.method == 'stripe'" v-bind:href="'https://dashboard.stripe.com/test/payments/' + d.paymentId" target="_blank" v-text="d.method.toUpperCase()"></a>

        												<p v-else v-text="d.method"></p>
        											</td>
        										</tr>

        										<tr>
        											<th>Price</th>
        											<td v-html="'&yen;' + d.price"></td>
        										</tr>

        										<tr>
        											<th>Date &amp; Time</th>
        											<td v-text="d.created_at"></td>
        										</tr>
        									</table>
        								</th>
        							</tr>

        							<tr v-else>
        								<td>
        									<span v-html="'<b>Name:</b> ' + d.name"></span><br />
        									<span v-html="'<b>Email:</b> ' + d.email"></span><br />
        									<span v-html="'<b>Mobile:</b> ' + d.mobile"></span><br />
        									<span v-html="'<b>Address:</b> ' + d.address"></span><br />
        									<span v-html="'<b>Country Code:</b> ' + d.countryCode"></span>
        								</td>

        								<td>
        									<a v-if="d.method == 'stripe'" v-bind:href="'https://dashboard.stripe.com/test/payments/' + d.paymentId" target="_blank" v-text="d.method.toUpperCase()"></a>

        									<p v-else v-text="d.method"></p>
        								</td>

        								<td v-html="'&yen;' + d.price"></td>
        								<td v-text="d.created_at"></td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "PaymentsComponent",

		data() {
			return {
				loading: false,
				data: [],
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null,
				search: ""
			}
		},

		methods: {
			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				this.pages = 0

				this.loading = true
				this.data = []

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("q", this.search)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/payments",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					}) 
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Payments"
		}
	}
</script>