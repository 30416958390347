<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Auction Cars</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Auction Cars</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
            	<div class="white-box">
            		<h3 class="box-title">Add Auction Car</h3>

            		<form v-on:submit.prevent="addAuctionCar" enctype="multipart/form-data">
            			<div class="form-group">
            				<label>Car ID</label>
            				<input type="text" v-model="carId" name="CAR_ID" class="form-control" />
            			</div>

            			<button type="button" class="btn btn-primary" v-on:click="getCar" v-bind:disabled="fetchingCar">
            				<span v-if="fetchingCar">Loading...</span>
            				<span v-else>Get Car</span>
            			</button>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>ID</label>
            				<input type="text" name="ID" class="form-control" v-model="car.ID" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>LOT</label>
            				<input type="text" name="LOT" class="form-control" v-model="car.LOT" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>Engine Power</label>
            				<input type="text" name="PW" class="form-control" v-model="car.PW" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>Average Price</label>
            				<input type="text" name="AVG_PRICE" class="form-control" v-model="car.AVG_PRICE" />
            			</div>

            			<div class="form-group">
							<label>Transmission</label>
							<input type="text" name="KPP" class="form-control" v-model="car.KPP" />
						</div>

						<div class="form-group">
							<label>Grade</label>
							<input type="text" name="RATE" class="form-control" v-model="car.RATE" />
						</div>

						<div class="form-group">
							<label>Package</label>
							<input type="text" name="GRADE" class="form-control" v-model="car.GRADE" />
						</div>

						<div class="form-group">
							<label>Chassis</label>
							<input type="text" name="KUZOV" class="form-control" v-model="car.KUZOV" />
						</div>

						<div class="form-group">
							<label>Auction House</label>
							<input type="text" name="AUCTION" class="form-control" v-model="car.AUCTION" />
						</div>

						<div class="form-group">
							<label>Marka Name</label>
							<input type="text" name="MARKA_NAME" class="form-control" v-model="car.MARKA_NAME" />
						</div>

						<div class="form-group">
							<label>Model Name</label>
							<input type="text" name="MODEL_NAME" class="form-control" v-model="car.MODEL_NAME" />
						</div>

						<div class="form-group">
							<label>Equipment</label>
							<input type="text" name="EQUIP" class="form-control" v-model="car.EQUIP" />
						</div>

						<div class="form-group">
							<label>Date</label>
							<input type="text" name="AUCTION_DATE" class="form-control" v-model="car.AUCTION_DATE" />
						</div>

						<div class="form-group">
							<label>Time</label>
							<input type="text" name="TIME" class="form-control" v-model="car.TIME" />
						</div>

						<div class="form-group">
							<label>Starting Price</label>
							<input type="text" name="START" class="form-control" v-model="car.START" />
						</div>

						<div class="form-group">
							<label>Status</label>
							<input type="text" name="STATUS" class="form-control" v-model="car.STATUS" />
						</div>

						<div class="form-group">
							<label>Finish Price</label>
							<input type="text" name="FINISH" class="form-control" v-model="car.FINISH" />
						</div>

						<div class="form-group">
							<label>Color</label>
							<input type="text" name="COLOR" class="form-control" v-model="car.COLOR" />
						</div>

						<div class="form-group">
							<label>Mileage</label>
							<input type="text" name="MILEAGE" class="form-control" v-model="car.MILEAGE" />
						</div>

						<div class="form-group">
							<label>Engine CC</label>
							<input type="text" name="ENG_V" class="form-control" v-model="car.ENG_V" />
						</div>

						<div class="form-group">
							<label>Year</label>
							<input type="text" name="YEAR" class="form-control" v-model="car.YEAR" />
						</div>

						<div class="form-group">
							<label>Information</label>
							<input type="text" name="INFO" class="form-control" v-model="car.INFO" />
						</div>

						<div class="form-group">
							<label>Images</label>
							<input type="file" name="images[]" accept="image/*" multiple class="form-control" />
						</div>

						<div class="row">
							<div class="col-md-2" v-for="(img, index) in car.IMAGES">
								<img v-bind:src="img" style="width: 100%;" />
							</div>
						</div>

						<button type="submit" class="btn btn-success" style="color: white; margin-top: 20px;" v-bind:disabled="addingCar">
							<span v-if="addingCar">Loading...</span>
							<span v-else>Add Car</span>
						</button>
            		</form>
            	</div>
            </div>
        </div>

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table v-if="!loading" class="table table-bordered" id="cars-data">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>CAR_ID</th>
        							<th>LOT</th>
        							<th>AUCTION_DATE</th>
        							<th>AUCTION</th>
        							<th>MARKA_ID</th>
        							<th>MODEL_ID</th>
        							<th>MARKA_NAME</th>
        							<th>MODEL_NAME</th>
        							<th>YEAR</th>
        							<th>ENG_V</th>
        							<th>PW</th>
        							<th>KUZOV</th>
        							<th>GRADE</th>
        							<th>COLOR</th>
        							<th>KPP</th>
        							<th>KPP_TYPE</th>
        							<th>PRIV</th>
        							<th>MILEAGE</th>
        							<th>EQUIP</th>
        							<th>RATE</th>
        							<th>START</th>
        							<th>FINISH</th>
        							<th>STATUS</th>
        							<th>TIME</th>
        							<th>AVG_PRICE</th>
        							<th>AVG_STRING</th>
        							<th>IMAGES</th>
        							<th>SERIAL</th>
        							<th>INFO</th>
        							<th>auctionSheet</th>
        							<th>awardedTo</th>
        							<th>type</th>
        						</tr>
        					</thead>

        					<tbody>

        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<th>
        									<table>
        										<tr>
        											<th class="border-top-0">CAR_ID</th>
        											<td>
				        								<a v-bind:href="$homeURL + '/carDetail/' + d.CAR_ID" v-text="d.CAR_ID"></a>

				        								<br /><br />

				        								<router-link v-bind:to="'/auctionCars/edit/' + d.CAR_ID">Edit</router-link>
				        							</td>
				        						</tr>

			        							<tr>
			        								<th class="border-top-0">LOT</th>
			        								<td v-text="d.LOT"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AUCTION_DATE</th>
			        								<td v-text="d.AUCTION_DATE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AUCTION</th>
			        								<td v-text="d.AUCTION"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MARKA_ID</th>
			        								<td v-text="d.MARKA_ID"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MODEL_ID</th>
			        								<td v-text="d.MODEL_ID"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MARKA_NAME</th>
			        								<td v-text="d.MARKA_NAME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MODEL_NAME</th>
			        								<td v-text="d.MODEL_NAME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">YEAR</th>
			        								<td v-text="d.YEAR"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">ENG_V</th>
			        								<td v-text="d.ENG_V"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">PW</th>
			        								<td v-text="d.PW"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KUZOV</th>
			        								<td v-html="d.KUZOV"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">GRADE</th>
			        								<td v-text="d.GRADE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">COLOR</th>
			        								<td v-text="d.COLOR"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KPP</th>
			        								<td v-text="d.KPP"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KPP_TYPE</th>
			        								<td v-text="d.KPP_TYPE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">PRIV</th>
			        								<td v-text="d.PRIV"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MILEAGE</th>
			        								<td v-text="d.MILEAGE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">EQUIP</th>
			        								<td v-text="d.EQUIP"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">RATE</th>
			        								<td v-text="d.RATE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">START</th>
			        								<td v-text="d.START"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">FINISH</th>
			        								<td v-text="d.FINISH"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">STATUS</th>
			        								<td v-text="d.STATUS"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">TIME</th>
			        								<td v-text="d.TIME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AVG_PRICE</th>
			        								<td v-text="d.AVG_PRICE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AVG_STRING</th>
			        								<td v-text="d.AVG_STRING"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">IMAGES</th>
			        								<td>
				        								<template v-for="a in 3">
				        									<img v-if="a < d.IMAGES.length" v-bind:src="d.IMAGES[a]" style="width: 100px; display: inline-block;" />
				        								</template>
				        							</td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">SERIAL</th>
			        								<td v-html="d.SERIAL"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">INFO</th>
			        								<td v-html="d.INFO"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">auctionSheet</th>
			        								<td v-html="d.auctionSheet"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">awardedTo</th>
			        								<td v-html="d.awardedTo"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">type</th>
			        								<td v-html="d.type"></td>
			        							</tr>
        									</table>
        								</th>
        							</tr>

        							<tr v-else>
        								<td>
	        								<a v-bind:href="$homeURL + '/carDetail/' + d.CAR_ID" v-text="d.CAR_ID"></a>

	        								<br /><br />

	        								<router-link v-bind:to="'/auctionCars/edit/' + d.CAR_ID">Edit</router-link>
	        							</td>
	        							<td v-text="d.LOT"></td>
	        							<td v-text="d.AUCTION_DATE"></td>
	        							<td v-text="d.AUCTION"></td>
	        							<td v-text="d.MARKA_ID"></td>
	        							<td v-text="d.MODEL_ID"></td>
	        							<td v-text="d.MARKA_NAME"></td>
	        							<td v-text="d.MODEL_NAME"></td>
	        							<td v-text="d.YEAR"></td>
	        							<td v-text="d.ENG_V"></td>
	        							<td v-text="d.PW"></td>
	        							<td v-html="d.KUZOV"></td>
	        							<td v-text="d.GRADE"></td>
	        							<td v-text="d.COLOR"></td>
	        							<td v-text="d.KPP"></td>
	        							<td v-text="d.KPP_TYPE"></td>
	        							<td v-text="d.PRIV"></td>
	        							<td v-text="d.MILEAGE"></td>
	        							<td v-text="d.EQUIP"></td>
	        							<td v-text="d.RATE"></td>
	        							<td v-text="d.START"></td>
	        							<td v-text="d.FINISH"></td>
	        							<td v-text="d.STATUS"></td>
	        							<td v-text="d.TIME"></td>
	        							<td v-text="d.AVG_PRICE"></td>
	        							<td v-text="d.AVG_STRING"></td>
	        							<td>
	        								<template v-for="a in 3">
	        									<img v-if="a < d.IMAGES.length" v-bind:src="d.IMAGES[a]" style="width: 100px; display: inline-block;" />
	        								</template>
	        							</td>
	        							<td v-html="d.SERIAL"></td>
	        							<td v-html="d.INFO"></td>
	        							<td v-html="d.auctionSheet"></td>
	        							<td v-html="d.awardedTo"></td>
	        							<td v-html="d.type"></td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "AuctionCarsComponent",

		data() {
			return {
				carId: "",
				fetchingCar: false,
				car: {},
				addingCar: false,
				loading: false,
				data: [],
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null,
				search: ""
			}
		},

		methods: {

			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			async getCar() {
				if (this.carId == "") {
					return
				}

				this.fetchingCar = true

				const formData = new FormData()
				formData.append("id", this.carId)

				try {
					const response = await axios.post(
						this.$apiURL + "/carDetail",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.car = response.data.car
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.fetchingCar = false
				}
			},

			async addAuctionCar() {
				this.addingCar = true

				const form = event.target
				const formData = new FormData(form)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/auctionCars/add",
						formData,
						{
							headers: this.$headers
						}
					)

					swal.fire("Auction Car", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.addingCar = false
				}
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.pages = 0

				this.loading = true
				this.data = []

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("search", this.search)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/auctionCars",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#cars-data").DataTable({
						ordering: false,
						pageLength: 1
					}) 
				} else {
					this.dataTable = jQuery("#cars-data").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Auction Cars"
		}
	}
</script>