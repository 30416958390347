<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Transaction Logs</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Transaction Logs</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Transaction Logs (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="table-data">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>User</th>
                                	<th>Action</th>
                                	<th>Date and Time</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">User</th>
													<td>
														<router-link v-text="d.name + ' | ' + d.email" v-bind:to="'/users/edit/' + d.loggedBy"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Action</th>
													<td v-text="d.action"></td>
												</tr>

												<tr>
													<th class="border-top-0">Date &amp; Time</th>
													<td v-text="d.created_at"></td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>
										<td>
											<router-link v-text="d.name + ' | ' + d.email" v-bind:to="'/users/edit/' + d.loggedBy"></router-link>
										</td>

										<td>
											<span v-text="d.action.split(':')[0]"></span>

											<p v-text="d.action.split(': ')[1]"></p>
										</td>

										<td v-text="d.created_at"></td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "TransactionLogsComponent",

		data() {
			return {
				loading: false,
				search: "",
				data: [],
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				id: this.$route.params.id || 0
			}
		},

		methods: {

			onKeyPressSearch: function() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

        	getData: async function () {
        		const self = this
        		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)
        		formData.append("id", this.id)
        		formData.append("timezone", timezone)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/transactions/logs",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#table-data").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#table-data").DataTable({
		            	ordering: false
		            })
				}
	        }
        },

		mounted() {
        	this.getData()
        	document.title = "Transaction Logs"
        }
	}
</script>