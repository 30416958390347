<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Pages</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Pages</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
            	<div class="white-box">
            		<h3 class="box-title">Add Page</h3>
            		<form v-on:submit.prevent="addPage">
            			<div class="form-group" style="margin-top: 20px;">
            				<label>Name</label>
            				<input type="text" name="name" class="form-control" />
            			</div>
						<div class="form-group">
							<label>Title</label>
							<input type="text" name="title" class="form-control" />
						</div>
						<div class="form-group">
							<label>Content</label>
							<div class="wysiwyg">
								<textarea name="content" class="form-control" rows="7"></textarea>
							</div>
						</div>
						<button type="submit" class="btn btn-success" style="color: white; margin-top: 20px;" v-bind:disabled="adding">
							<span v-if="adding">Loading...</span>
							<span v-else>Add Page</span>
						</button>
            		</form>
            	</div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive" style="margin-top: 20px;">
        				<table class="table table-bordered" id="data-table">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Name</th>
        							<th>Title</th>
        							<th>Status</th>
        							<th v-if="hasPermission('addPage')">Actions</th>
        						</tr>
        					</thead>

        					<tbody>

        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<th>
        									<table>
        										<tr>
        											<th>Name</th>
        											<td>
        												<a v-bind:href="$homeURL + '/' + d.name" v-text="d.name" target="_blank"></a>
        											</td>
        										</tr>
        										<tr>
        											<th>Title</th>
        											<td v-text="d.title"></td>
        										</tr>
        										<tr>
        											<th>Status</th>
        											<td v-text="d.status"></td>
        										</tr>
        										<tr v-if="hasPermission('addPage')">
        											<th>Actions</th>
        											<td>
	        											<router-link v-bind:to="'/pages/edit/' + d.id">
															Edit
														</router-link>
														<button type="button" class="btn btn-danger" v-on:click="deletePage(d.id)" style="color: white; margin-left: 10px;">Delete</button>
        											</td>
        										</tr>
        									</table>
        								</th>
        							</tr>

        							<tr v-else>
        								<td>
        									<a v-bind:href="$homeURL + '/' + d.name" v-text="d.name" target="_blank"></a>
        								</td>
        								<td v-text="d.title"></td>
        								<td v-text="d.status"></td>
        								<td v-if="hasPermission('addPage')">
											<router-link v-bind:to="'/pages/edit/' + d.id">
												Edit
											</router-link>
											<button type="button" class="btn btn-danger" v-on:click="deletePage(d.id)" style="color: white; margin-left: 10px;">Delete</button>
        								</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"
	import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "PagesComponent",
		data() {
			return {
				adding: false,
				data: []
			}
		},
		methods: {
			async addPage() {
				this.adding = true
				const form = event.target
				const formData = new FormData(form)
				try {
					const response = await axios.post(
						this.$apiURL + "/admin/pages/add",
						formData,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						swal.fire("Add Page", response.data.message, "success")
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.adding = false
				}
			},

			async getData() {
				const self = this
				this.loading = true
				this.data = []
				if (this.dataTable != null) {
					this.dataTable.destroy()
				}
				try {
					const response = await axios.post(
						this.$apiURL + "/admin/pages",
						null,
						{
							headers: this.$headers
						}
					)
					if (response.data.status == "success") {
						this.data = response.data.data
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					}) 
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}
			}
		},
		mounted() {
			this.getData()
			document.title = "Pages"
		}
	}
</script>