import jQuery from "jquery"

jQuery(function() {
    "use strict";

    //****************************
    /* Top navbar Theme Change function Start */
    //****************************
    function handlenavbarbg() {
        if ( jQuery('#main-wrapper').attr('data-navbarbg') == 'skin6' ) {
            // do this
            jQuery(".topbar .navbar").addClass('navbar-light');
            jQuery(".topbar .navbar").removeClass('navbar-dark');
        } else {
            // do that    
        }    
    };

    handlenavbarbg();
});