<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Requested Images</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Requested Images</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Select Date</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" v-model="date" class="form-control p-0 border-0 date" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Requested Images</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="data">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>#</th>
                                	<th>User</th>
                                	<th>Car</th>
                                	<th>Info</th>
                                	<th>Images</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">#</th>
													<td v-text="d.id"></td>
												</tr>

												<tr>
													<th class="border-top-0">User</th>
													<td>
														<router-link v-bind:to="'/users/edit/' + d.userId" v-text="d.username"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Car</th>
													<td>
														<a v-bind:href="$homeURL + '/carDetail/' + d.CAR_ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME"></a>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Info</th>
													<td>
														<p v-text="'LOT: ' + d.LOT"></p>
														<p v-text="'Auction House: ' + d.AUCTION"></p>
														<p v-text="'Auction Date: ' + d.AUCTION_DATE"></p>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Images</th>
													<td>
														<p style="margin-top: 0px; margin-bottom: 0px;" v-text="d.status"></p>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<form v-on:submit.prevent="uploadCarImages">
															<input type="hidden" name="id" v-model="d.id" required />
															<input type="file"
																accept="image/*,video/*"
																multiple
																name="images[]" />
															<button type="submit"
																name="submit"
																class="btn btn-success"
																style="color: white;">Upload</button>
														</form>
													</td>
												</tr>
											</table>
										</th>
									</tr>

									<tr v-else>
										<td v-text="d.id"></td>

										<td>
											<router-link v-bind:to="'/users/edit/' + d.userId" v-text="d.username"></router-link>
										</td>

										<td>
											<a v-bind:href="$homeURL + '/carDetail/' + d.CAR_ID" v-text="d.MARKA_NAME + ' ' + d.MODEL_NAME"></a>
										</td>

										<td>
											<p v-text="'LOT: ' + d.LOT"></p>
											<p v-text="'Auction House: ' + d.AUCTION"></p>
											<p v-text="'Auction Date: ' + d.AUCTION_DATE"></p>
										</td>

										<td>
											<p style="margin-top: 0px; margin-bottom: 0px;" v-text="d.status"></p>
										</td>

										<td>
											<form v-on:submit.prevent="uploadCarImages">
												<input type="hidden" name="id" v-model="d.id" required />
												<input type="file"
													accept="image/*,video/*"
													multiple
													name="images[]" />
												<button type="submit"
													name="submit"
													class="btn btn-success"
													style="color: white;">Upload</button>
											</form>
										</td>
									</tr>
								</template>
							</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

    import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "RequestedImagesComponent",

		data() {
			return {
				loading: false,
				data: [],
				date: "",
				dataTable: null
			}
		},

		methods: {

			uploadCarImages: async function () {
				const form = event.target
				const formData = new FormData(form)

				form.submit.setAttribute("disabled", "disabled")
				form.submit.innerHTML = "Uploading..."

				try {
					const response = await axios.post(
				        this.$apiURL + "/admin/requests/images/upload",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    if (response.data.status == "success") {
				    	for (let a = 0; a < this.data.length; a++) {
				    		if (this.data[a].id == form.id.value) {
				    			this.data[a].status = "uploaded"
				    		}
				    	}
				    	swal.fire("Upload Images", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					form.submit.removeAttribute("disabled")
					form.submit.innerHTML = "Upload"
				}
			},

			getData: async function () {
        		const self = this

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("date", this.date)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/requests/images",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {
				const self = this

	            jQuery(".date").datetimepicker({
	                "format": "Y-m-d",
	                "timepicker": false,
	                "onSelectDate": function() {
	                	const d = jQuery('.date').datetimepicker('getValue')
	                	self.getDate(null, new Date(d))
	                	self.addOrUpdateURLParam("date", self.date)
	                    self.getData()
	                },
	                "scrollMonth": false
	            })

	            if (this.$isMobile()) {
	            	this.dataTable = jQuery("#data").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
	            } else {
	            	this.dataTable = jQuery("#data").DataTable({
		            	ordering: false
		            }) 
	            }
	        },

			getDate(date, dateObj) {
	        	if (!date) {
					let month = (dateObj.getMonth() + 1)
					if (month < 10) {
						month = "0" + month
					}

					let dateVal = dateObj.getDate()
					if (dateVal < 10) {
						dateVal = "0" + dateVal
					}

					date = dateObj.getFullYear() + "-" + month + "-" + dateVal
				}

				this.date = date
	        }
		},

		mounted() {
			this.getDate(this.$route.query.date, new Date())
        	this.getData()
        	document.title = "Requested Images"
        }
	}
</script>