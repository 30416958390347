<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Auction Cars</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Auction Cars</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
            	<div class="white-box">
            		<h3 class="box-title">Edit Auction Car</h3>

            		<div class="text-center" v-if="loading">
            			<div class="spinner-border"></div>
            		</div>

            		<form v-on:submit.prevent="editAuctionCar" enctype="multipart/form-data">
            			<div class="form-group">
            				<label>Car ID</label>
            				<input type="text" v-model="carId" name="CAR_ID" class="form-control" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>LOT</label>
            				<input type="text" name="LOT" class="form-control" v-model="car.LOT" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>Engine Power</label>
            				<input type="text" name="PW" class="form-control" v-model="car.PW" />
            			</div>

            			<div class="form-group" style="margin-top: 20px;">
            				<label>Average Price</label>
            				<input type="text" name="AVG_PRICE" class="form-control" v-model="car.AVG_PRICE" />
            			</div>

            			<div class="form-group">
							<label>Transmission</label>
							<input type="text" name="KPP" class="form-control" v-model="car.KPP" />
						</div>

						<div class="form-group">
							<label>Grade</label>
							<input type="text" name="RATE" class="form-control" v-model="car.RATE" />
						</div>

						<div class="form-group">
							<label>Package</label>
							<input type="text" name="GRADE" class="form-control" v-model="car.GRADE" />
						</div>

						<div class="form-group">
							<label>Chassis</label>
							<input type="text" name="KUZOV" class="form-control" v-model="car.KUZOV" />
						</div>

						<div class="form-group">
							<label>Auction House</label>
							<input type="text" name="AUCTION" class="form-control" v-model="car.AUCTION" />
						</div>

						<div class="form-group">
							<label>Marka Name</label>
							<input type="text" name="MARKA_NAME" class="form-control" v-model="car.MARKA_NAME" />
						</div>

						<div class="form-group">
							<label>Model Name</label>
							<input type="text" name="MODEL_NAME" class="form-control" v-model="car.MODEL_NAME" />
						</div>

						<div class="form-group">
							<label>Equipment</label>
							<input type="text" name="EQUIP" class="form-control" v-model="car.EQUIP" />
						</div>

						<div class="form-group">
							<label>Date</label>
							<input type="text" name="AUCTION_DATE" class="form-control" v-model="car.AUCTION_DATE" />
						</div>

						<div class="form-group">
							<label>Time</label>
							<input type="text" name="TIME" class="form-control" v-model="car.TIME" />
						</div>

						<div class="form-group">
							<label>Starting Price</label>
							<input type="text" name="START" class="form-control" v-model="car.START" />
						</div>

						<div class="form-group">
							<label>Status</label>
							<input type="text" name="STATUS" class="form-control" v-model="car.STATUS" />
						</div>

						<div class="form-group">
							<label>Finish Price</label>
							<input type="text" name="FINISH" class="form-control" v-model="car.FINISH" />
						</div>

						<div class="form-group">
							<label>Color</label>
							<input type="text" name="COLOR" class="form-control" v-model="car.COLOR" />
						</div>

						<div class="form-group">
							<label>Mileage</label>
							<input type="text" name="MILEAGE" class="form-control" v-model="car.MILEAGE" />
						</div>

						<div class="form-group">
							<label>Engine CC</label>
							<input type="text" name="ENG_V" class="form-control" v-model="car.ENG_V" />
						</div>

						<div class="form-group">
							<label>Year</label>
							<input type="text" name="YEAR" class="form-control" v-model="car.YEAR" />
						</div>

						<div class="form-group">
							<label>Information</label>
							<input type="text" name="INFO" class="form-control" v-model="car.INFO" />
						</div>

						<div class="form-group">
							<label>Images</label>
							<input type="file" name="images[]" accept="image/*" multiple class="form-control" />
						</div>

						<div class="row">
							<div class="col-md-2" v-for="(img, index) in car.IMAGES">
								<img v-bind:src="img" style="width: 100%;" />
							</div>
						</div>

						<button type="submit" class="btn btn-warning" style="color: white; margin-top: 20px;" v-bind:disabled="updatingCar">
							<span v-if="updatingCar">Loading...</span>
							<span v-else>Edit Car</span>
						</button>
            		</form>
            	</div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

	export default {
		name: "AuctionCarsComponent",

		data() {
			return {
				carId: this.$route.params.carId || "",
				car: {},
				loading: false,
				updatingCar: false
			}
		},

		methods: {

			async editAuctionCar() {
				this.updatingCar = true

				const form = event.target
				const formData = new FormData(form)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/auctionCars/update",
						form,
						{
							headers: this.$headers
						}
					)

					swal.fire("Auction Car", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.updatingCar = false
				}
			},

			async getData() {
				this.loading = true

				const formData = new FormData()
				formData.append("id", this.carId)

				try {
					const response = await axios.post(
						this.$apiURL + "/carDetail",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.car = response.data.car
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			},
		},

		mounted() {
			this.getData()
			document.title = "Edit Auction Car"
		}
	}
</script>