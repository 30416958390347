<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Testimonials</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Testimonials</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">
						<button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAddTestimonial" aria-expanded="false" aria-controls="collapseAddTestimonial">
						    Add Testimonial
						</button>
                	</h3>

                	<div class="collapse" id="collapseAddTestimonial">
						<div class="card card-body">
							<form v-on:submit.prevent="addTestimonial">
		                		<div class="form-group">
									<label class="form-label">Title</label>
									<input type="text" name="title" placeholder="Enter Title" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Content</label>
									<textarea type="text" name="content" placeholder="Enter Review" class="form-control"></textarea>
								</div>

								<div class="form-group">
									<label class="form-label">Name</label>
									<input type="text" name="name" placeholder="Enter User Name" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Stars</label>
									<input type="number" min="0" step="1" max="5" name="stars" placeholder="Enter Stars" class="form-control" />
								</div>

								<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
									Add Testimonial

									<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
								</button>
		                	</form>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<h3 class="box-title">Testimonials</h3>

        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table class="table" id="data-table">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Title</th>
        							<th>Content</th>
        							<th>Name</th>
        							<th>Stars</th>
        							<th>Actions</th>
        						</tr>
        					</thead>

        					<tbody>
        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<td>
        									<table>
        										<tr>
        											<th>Title</th>
        											<td v-text="d.title"></td>
        										</tr>

        										<tr>
        											<th>Content</th>
        											<td v-html="d.content"></td>
        										</tr>

        										<tr>
        											<th>Name</th>
        											<td v-text="d.name"></td>
        										</tr>

        										<tr>
        											<th>Stars</th>
        											<td v-text="d.stars"></td>
        										</tr>

        										<tr>
        											<th>Actions</th>
        											<td>
        												<router-link v-bind:to="'/testimonials/edit/' + d.id">Edit</router-link>

														<button type="button" v-on:click="deleteData(d.id)" class="btn btn btn-danger text-white">Delete</button>
        											</td>
        										</tr>
        									</table>
        								</td>
        							</tr>

        							<tr v-else>
        								<td v-text="d.title"></td>
        								<td v-html="d.content"></td>
        								<td v-text="d.name"></td>
        								<td v-text="d.stars"></td>

										<td>
											<router-link v-bind:to="'/testimonials/edit/' + d.id">Edit</router-link>

											<button type="button" v-on:click="deleteData(d.id)" class="btn btn btn-danger text-white">Delete</button>
										</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>

        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../../vuex/store"
    import jQuery from "jquery"

    import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "TestimonialsComponent",

		data() {
			return {
				isAdding: false,
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {
			deleteData(id) {
				const self = this
				const target = event.target

				swal.fire({
					title: 'Delete Testimonial',
					text: "Are you sure you want to delete this testimonial ?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, delete it!'
				}).then(async function (result) {
					if (result.isConfirmed) {
						const formData = new FormData()
		        		formData.append("id", id)

		        		target.setAttribute("disabled", "disabled")
		        		target.innerHTML = "Loading..."

						try {
							const response = await axios.post(
						        self.$apiURL + "/admin/testimonials/delete",
						        formData,
						        {
						            headers: self.$headers
						        }
						    )
						 
						    if (response.data.status == "success") {
						    	swal.fire("Delete Testimonial", response.data.message, "success")
						    } else {
						        swal.fire("Error", response.data.message, "error")
						    }
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							target.removeAttribute("disabled")
		        			target.innerHTML = "Delete Testimonial"
						}
					}
				})
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.loading = true
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/testimonials",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize() 
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}
			},

			async addTestimonial() {
				const form = event.target
				const formData = new FormData(form)
				this.isAdding = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/testimonials/add",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Add Testimonial", response.data.message, response.data.status)

					if (response.data.status == "success") {
						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Testimonials"
		}
	}
</script>