import jQuery from "jquery"

let clickTimer = null
global.isNavtoggler = false

global.attachEventListeners = function() {

    window.addEventListener('click', function(e) {
        clearTimeout(clickTimer) 
        if (document.querySelector('.left-sidebar').contains(e.target)) {
            // Clicked in box
            // console.log("inside")
        } else if (e.target.parentElement && e.target.parentElement.className.includes("nav-toggler")) {
            // Clicked outside the box
            clickTimer = setTimeout(function () {

                global.isNavtoggler = true

                jQuery("#main-wrapper").toggleClass("show-sidebar")
                jQuery(".nav-toggler i").toggleClass("ti-menu")
                
                // document.getElementById("main-wrapper").className = document.getElementById("main-wrapper").className.replace("show-sidebar", "")
                // document.querySelector(".nav-toggler i").className = document.querySelector(".nav-toggler i").className.replace("ti-menu", "")
            }, 100)
        } else if (global.isNavtoggler) {
            jQuery("#main-wrapper").toggleClass("show-sidebar")
            jQuery(".nav-toggler i").toggleClass("ti-menu")
            global.isNavtoggler = false
        }
    })

    // this is for close icon when navigation open in mobile view
    // jQuery(".nav-toggler").on('click', function() {
    //     jQuery("#main-wrapper").toggleClass("show-sidebar");
    //     jQuery(".nav-toggler i").toggleClass("ti-menu");
    // });
    jQuery(".search-box a, .search-box .app-search .srh-btn").on('click', function() {
        jQuery(".app-search").toggle(200);
        jQuery(".app-search input").focus();
    });

    // ============================================================== 
    // Resize all elements
    // ============================================================== 
    jQuery("body, .page-wrapper").trigger("resize");
    jQuery(".page-wrapper").delay(20).show();
    
    //****************************
    /* This is for the mini-sidebar if width is less then 1170*/
    //**************************** 
    var setsidebartype = function() {
        var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
        if (width < 1170) {
            jQuery("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
        } else {
            jQuery("#main-wrapper").attr("data-sidebartype", "full");
        }
    };
    jQuery(window).ready(setsidebartype);
    jQuery(window).on("resize", setsidebartype);
}

jQuery(function() {
    "use strict";

    jQuery(".preloader").fadeOut();

    attachEventListeners()

});