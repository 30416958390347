import { createStore } from "vuex"
 
export default createStore({
    state() {
        return {
            user: null,
            loading: false,
            messages: [],
            selectedUserEmail: "",
            selectedUserName: "",
            socketIO: null
        }
    },
 
    mutations: {
        setSocketIO (state, val) {
            state.socketIO = val
        },

        setSelectedUserName (state, val) {
            state.selectedUserName = val
        },

        setSelectedUserEmail (state, val) {
            state.selectedUserEmail = val
        },

        pushMessage (state, val) {
            state.messages.push(val)
        },

        setMessages (state, val) {
            state.messages = val
        },

        setLoading (state, val) {
            state.loading = val
        },

        setUser (state, val) {
            state.user = val
        },
    },
 
    getters: {
        getSocketIO (state) {
            return state.socketIO
        },

        getSelectedUserName (state) {
            return state.selectedUserName
        },

        getSelectedUserEmail (state) {
            return state.selectedUserEmail
        },

        getMessages (state) {
            return state.messages
        },

        getLoading (state) {
            return state.loading
        },

        getUser (state) {
            return state.user
        }
    }
})