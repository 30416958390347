<template>
    <div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Chats</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Chats</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Chats</h3>
                    
                    <div class="container">
                        <h3 class="text-center">Messaging</h3>
                        <div class="messaging">
                            <div class="inbox_msg">
                                <div class="inbox_people">
                                    <div class="headind_srch">
                                        <div class="recent_heading">
                                            <h4>Recent</h4>
                                        </div>
                                        <div class="srch_bar">
                                            <div class="stylish-input-group">
                                                <input type="text" class="search-bar" placeholder="Search" v-model="search" v-on:keyup="onkeyupSearch" />
                                                <span class="input-group-addon">
                                                    <button type="button"> <i class="fa fa-search" aria-hidden="true"></i> </button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="inbox_chat">
                                        <template v-for="(u, index) in users">
                                            <div v-bind:class="'chat_list' + (u.email == selectedUserEmail ? ' active_chat' : '')" v-on:click="fetchUserChat(u.email)" style="cursor: pointer;">
                                                <div class="chat_people">
                                                    <div class="chat_img">
                                                        <img src="https://ptetutorials.com/images/user-profile.png" />
                                                    </div>
                                                    
                                                    <div class="chat_ib">
                                                        <h5> <span v-text="u.name" style="float: none !important; font-size: initial;"></span>
                                                            <span class="chat_date" v-text="u.updated_at"></span>
                                                        </h5>
                                                        <p v-text="u.lastMessage"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="mesgs">

                                    <div class="text-center" v-if="loading" style="margin-bottom: 50px;">
                                        <div class="spinner-border">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>

                                    <button type="button" v-if="hasMoreMessages" class="btn btn-light btn-load-more-chat" style="margin-bottom: 50px;
                                        position: relative;
                                        left: 50%;" v-on:click="loadMoreChat">
                                        <i v-bind:class="'ti-reload ' + loadMoreAnimationClass"></i>
                                    </button>

                                    <div class="msg_history" id="messages-chat">

                                        <template v-for="(message, index) in messages">
                                            <template v-if="message.type == 'joined'">
                                                <p class="text" style="margin-bottom: 25px;
                                                    width: fit-content;
                                                    text-align: center;
                                                    background-color: #ebebeb;
                                                    padding: 5px 10px;
                                                    border-radius: 5px;
                                                    position: relative;
                                                    left: 50%;
                                                    transform: translateX(-50%);">
                                                    <span v-html="message.message"></span>
                                                </p>
                                            </template>

                                            <template v-else-if="message.type == 'message'">
                                                <template v-if="message.sentBy == 'user'">
                                                    <div class="incoming_msg">
                                                        <div class="incoming_msg_img">
                                                            <img src="https://ptetutorials.com/images/user-profile.png" />
                                                        </div>

                                                        <div class="received_msg">
                                                            <div class="received_withd_msg">
                                                                <p v-html="message.message"></p>
                                                                <span class="time_date" v-text="message.sentAt"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <template v-else-if="message.sentBy == 'admin'">
                                                    <div class="outgoing_msg">
                                                        <div class="sent_msg">
                                                            <p v-html="message.message"></p>
                                                            <span class="time_date" v-text="message.sentAt"></span>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </template>

                                    </div>

                                    <div class="type_msg">
                                        <div class="input_msg_write">
                                            <template v-if="requireJoinChat">
                                                <button type="button" class="btn btn-success" style="color: white;
                                                    width: 100%;
                                                    margin-top: 10px;" v-on:click="joinChat" v-bind:disabled="joiningChat">
                                                    Join Chat
                                                    <i v-if="joiningChat" class="fa fa-spinner fa-spin"></i>
                                                </button>
                                            </template>

                                            <template v-else>
                                                <input type="text" class="write_msg" placeholder="Type a message" v-on:keyup="onkeyupMessage" v-model="message" />
                                                <button class="msg_send_btn" type="button" v-on:click="sendMessage" v-bind:disabled="sendingMessage">
                                                    <i class="fa fa-spinner fa-spin" v-if="sendingMessage"></i>
                                                    <i class="fa fa-paper-plane" v-else></i>
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios"
import swal from "sweetalert2"
import { io } from "socket.io-client"
import store from "../vuex/store"

export default {
    name: "MessagesComponent",

    computed: {
        user() {
            return store.getters.getUser
        },

        messages() {
            return store.getters.getMessages
        },

        selectedUserEmail() {
            return store.getters.getSelectedUserEmail
        },

        selectedUserName() {
            return store.getters.getSelectedUserName
        },

        socketIO() {
            return store.getters.getSocketIO
        }
    },

    data() {
        return {
            loading: false,
            search: "",
            users: [],
            chatPage: 1,
            chatPages: 0,
            message: "",
            sendingMessage: false,
            hasMoreMessages: false,
            loadMoreAnimationClass: "",
            requireJoinChat: false,
            joiningChat: false
        }
    },

    methods: {
        async joinChat() {
            const self = this
            this.joiningChat = true

            const formData = new FormData()
            formData.append("email", this.selectedUserEmail)

            try {
                const response = await axios.post(
                    this.$apiURL + "/admin/messages/joinChat",
                    formData, {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    if (this.socketIO != null) {
                        this.socketIO.emit("newMessage", {
                            email: self.selectedUserEmail,
                            message: response.data.messageObj
                        })
                    }

                    this.requireJoinChat = false
                    store.commit("pushMessage", response.data.messageObj)
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.joiningChat = false
            }
        },

        onkeyupMessage() {
            if (event.keyCode == 13) {
                this.sendMessage()
            }
        },

        onkeyupSearch() {
            if (event.keyCode == 13) {
                this.fetchUsersOnMessages()
            }
        },

        async loadMoreChat() {
            this.loadMoreAnimationClass = "btn-load-more-animate"

            this.chatPage++
            this.fetchUserChat(this.selectedUserEmail)
        },

        async sendMessage() {
            if (this.selectedUserEmail == "") {
                return
            }

            const self = this
            this.sendingMessage = true
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const formData = new FormData()
            formData.append("timezone", timezone)
            formData.append("userEmail", this.selectedUserEmail)
            formData.append("message", this.message)

            try {
                const response = await axios.post(
                    this.$apiURL + "/admin/messages/send",
                    formData, {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    if (this.socketIO != null) {
                        this.socketIO.emit("newMessage", {
                            email: self.selectedUserEmail,
                            message: response.data.messageObj
                        })
                    }

                    store.commit("pushMessage", response.data.messageObj)
                    // this.messages.push(response.data.message)
                    this.message = ""
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.sendingMessage = false
            }
        },

        async fetchUserChat(userEmail) {
            if (!userEmail) {
                return
            }

            const self = this
            this.loading = true
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const formData = new FormData()
            formData.append("timezone", timezone)
            formData.append("userEmail", userEmail)
            formData.append("page", this.chatPage)

            try {
                const response = await axios.post(
                    this.$apiURL + "/admin/messages/fetchUserMessages",
                    formData, {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    response.data.messages.forEach(function(message) {
                        store.commit("setMessages", self.prependArray(message, self.messages))
                    })

                    this.chatPages = response.data.pages
                    store.commit("setSelectedUserName", response.data.user.name)
                    this.hasMoreMessages = response.data.hasMoreMessages
                    store.commit("setSelectedUserEmail", userEmail)
                    this.loadMoreAnimationClass = ""

                    if (this.chatPage == 1) {
                        setTimeout(function() {
                            document.getElementById("messages-chat").scrollTop = document.getElementById("messages-chat").scrollHeight
                        }, 1000)

                        if (this.user != null) {
                            this.requireJoinChat = response.data.lastAgentEmail != this.user.email
                        }
                    }
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.loading = false
            }
        },

        async fetchUsersOnMessages() {
            const self = this
            this.loading = true
            this.users = []
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

            const formData = new FormData()
            formData.append("timezone", timezone)
            formData.append("search", this.search)

            try {
                const response = await axios.post(
                    this.$apiURL + "/admin/messages/fetchUsersOnMessages",
                    formData, {
                        headers: this.$headers
                    }
                )

                if (response.data.status == "success") {
                    this.users = response.data.users
                } else {
                    swal.fire("Error", response.data.message, "error")
                }
            } catch (error) {
                if (error?.response?.status == 401) {
                    swal.fire("Error", error.response.data.message || "Unauthorized", "error")
                }
            } finally {
                this.loading = false
            }
        }
    },

    mounted() {
        this.fetchUsersOnMessages()
        document.title = "Chats"
    }
}

</script>
<style scoped>

.container{max-width:1170px; margin:auto;}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}


.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}
.received_msg {
  display: inline-block;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 92%;
 }
 .received_withd_msg p {
  background: #ebebeb none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.received_withd_msg { width: 57%;}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}

 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0; color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.outgoing_msg{ overflow:hidden; margin:26px 0 26px;}
.sent_msg {
  float: right;
  width: 46%;
}
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  background: #05728f none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 11px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}

</style>
