<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">SMS</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">SMS</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

    	<div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<form style="display: contents;" v-on:submit.prevent="doSearch">
	                	<div class="form-group">
	                		<label class="form-label">Search SMS</label>
	                		<input type="text" name="search" class="form-control" v-model="search" />
	                	</div>

	                	<button type="submit" class="btn btn-primary">
	                		Search <i class="fa fa-spinner fa-spin" v-if="searching"></i>
	                	</button>
                	</form>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">
						<button class="btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSendSms" aria-expanded="false" aria-controls="collapseSendSms">
						    Send SMS
						</button>
                	</h3>

                	<div class="collapse" id="collapseSendSms">
						<div class="card card-body">
							<form v-on:submit.prevent="sendSms">
		                		<div class="form-group">
									<label class="form-label">From</label>
									<input type="text" name="from" placeholder="Enter phone number used to send SMS" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Recipient</label>
									<input type="text" name="phone" placeholder="Enter recipient phone number" class="form-control" />
								</div>

								<div class="form-group">
									<label class="form-label">Message</label>
									<textarea name="message" placeholder="Enter message" class="form-control"></textarea>
								</div>

								<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
									Send SMS
									<i class="fa fa-spinner fa-spin" v-if="isAdding"></i>
								</button>
		                	</form>
						</div>
					</div>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<h3 class="box-title">All SMS</h3>

        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table class="table" id="data-table" v-if="!loading">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Recipient</th>
        							<th>From</th>
        							<th>Sent By</th>
        							<th>Message</th>
        							<th>Sent At</th>
        						</tr>
        					</thead>

        					<tbody>
        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<td>
        									<table>
        										<tr>
        											<th>Recipient</th>
        											<td v-text="d.phone"></td>
        										</tr>

        										<tr>
        											<th>From</th>
        											<td v-text="d.from"></td>
        										</tr>

        										<tr>
        											<th>Sent By</th>
        											<td>
        												<router-link v-bind:to="'/users/edit/' + d.sentBy" v-text="d.name + ' | ' + d.email"></router-link>
        											</td>
        										</tr>

        										<tr>
        											<th>Message</th>
        											<td v-html="d.message"></td>
        										</tr>

        										<tr>
        											<th>Sent At</th>
        											<td v-text="d.sentAt"></td>
        										</tr>
        									</table>
        								</td>
        							</tr>

        							<tr v-else>
        								<td v-text="d.phone"></td>
        								<td v-text="d.from"></td>

        								<td>
        									<router-link v-bind:to="'/users/edit/' + d.sentBy" v-text="d.name + ' | ' + d.email"></router-link>
        								</td>

        								<td v-html="d.message"></td>
        								<td v-text="d.sentAt"></td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>

        		</div>
        	</div>
        </div>

    </div>
</template>

<script>
	import axios from "axios"
    import swal from "sweetalert2"
    import store from "../vuex/store"
    import jQuery from "jquery"

	export default {
		name: "SMSComponent",

		data() {
			return {
				isAdding: false,
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				searching: false,
				search: ""
			}
		},

		methods: {
			doSearch() {
				this.searching = true
				this.pageNumber = 1
				this.getData()
			},

			async sendSms() {
				const form = event.target
				const formData = new FormData(form)
				this.isAdding = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/sms/send",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Send SMS", response.data.message, response.data.status)

					if (response.data.status == "success") {
						form.reset()
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isAdding = false
				}
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.loading = true
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)
				formData.append("search", this.search)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/sms",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
					this.searching = false

					setTimeout(function () {
						self.initialize() 
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data-table").DataTable({
						ordering: false
					})
				}
			},
		},

		mounted() {
			this.getData()
			document.title = "SMS"
		}
	}
</script>