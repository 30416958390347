<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Mails</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Mails</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" name="search" v-on:keypress="onKeyPressSearch" v-model="search" class="form-control p-0 border-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">

                    <div class="text-center" v-if="loading">
                    	<div class="spinner-border"></div>
                    </div>

                    <div class="table-responsive">
                    	<table class="table" id="mails-data" v-if="!loading">
                    		<thead>
                    			<tr v-if="$isMobile()">
	                    			<th></th>
	                    		</tr>

	                    		<tr v-else>
	                    			<th>Subject</th>
	                    			<th>Email</th>
	                    			<th>Content</th>
	                    			<th>Date &amp; Time</th>
	                    		</tr>
                    		</thead>

                    		<tbody>
                    			<template v-for="(d, index) in data">
                    				<tr v-if="$isMobile()">
                    					<td>
                    						<table>
                    							<tr>
                    								<th>Subject</th>
                    								<td v-html="d.subject"></td>
                    							</tr>

                    							<tr>
                    								<th>Email</th>
                    								<td v-html="d.email"></td>
                    							</tr>

                    							<tr>
                    								<th>Content</th>
                    								<td>
			                    						<a href="javascript:void(0)" v-on:click="fetchMail(d.id)">
			                    							View
			                    						</a>
			                    					</td>
                    							</tr>

                    							<tr>
                    								<th>Date &amp; Time</th>
                    								<td v-text="d.created_at"></td>
                    							</tr>
                    						</table>
                    					</td>
                    				</tr>

                    				<tr v-else>
                    					<td v-html="d.subject"></td>
                    					<td v-html="d.email"></td>
                    					<td>
                    						<a href="javascript:void(0)" v-on:click="fetchMail(d.id)">
                    							View
                    						</a>
                    					</td>
                    					<td v-text="d.created_at"></td>
                    				</tr>
                    			</template>
                    		</tbody>
                    	</table>
                    </div>

                    <nav v-if="pages > 0">
                    	<ul class="pagination">
                    		<li v-for="(page, index) in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
                    			<a href="javascript:void(0)" class="page-link" v-text="page" v-on:click="paginate(page)"></a>
                    		</li>
                    	</ul>
                    </nav>

                </div>
            </div>
        </div>
    </div>

    <div class="modal" id="mail-modal">
    	<div class="modal-dialog modal-lg">
    		<div class="modal-content">
    			<div class="modal-header">
    				<h2 class="modal-title" v-html="mail?.subject + ' (' + mail?.email + ') (' + mail?.created_at + ')'"></h2>

    				<button type="button" style="background: none; border: none; font-size: 20px;" v-on:click="closeMailModal">x</button>
    			</div>

    			<div class="modal-body">
    				<p style="white-space: pre; overflow: scroll;" v-html="mail?.content"></p>
    			</div>
    		</div>
    	</div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "MailsComponent",

		data() {
			return {
				search: "",
				pageNumber: this.$route.query.page || 1,
				loading: false,
				data: [],
				dataTable: null,
				pages: 0,
				mail: null
			}
		},

		methods: {

			closeMailModal() {
				$("#mail-modal").modal("hide")
			},

			async fetchMail(id) {
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				const target = event.target
				target.innerHTML = "Loading..."

				const formData = new FormData()
				formData.append("id", id)
				formData.append("timezone", timezone)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/mails/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.mail = response.data.data
						$("#mail-modal").modal("show")
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					target.innerHTML = "View"
				}
			},

			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
				this.loading = true

				const formData = new FormData()
				formData.append("search", this.search)
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/mails",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#mails-data").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#mails-data").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Mails"
		}
	}
</script>