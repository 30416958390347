<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Translate Auction Sheet</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Translate Auction Sheet</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Translate Auction Sheet</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

				    <template v-if="data != null">
				    	<div class="row">
	                        <div class="col-md-12 text-center">
	                            <h2>Step 1</h2>
	                            <p>Crop the car body area from original auction sheet</p>
	                        </div>
	                    </div>

	                    <div class="row">
	                        <div class="offset-md-3 col-md-6">
	                            <div class="map-container" id='printMe' style="display: flex;">
	                                <div id="html-content-holder" style="width:587px !important;">
	                                	<img v-if="data.IMAGES.length > 0" v-bind:src="data.IMAGES[0]"
	                                        style="width: 100%;"
	                                        id="original-auction-sheet" />
	                                </div>
	                            </div>
	                        </div>
	                    </div>

	                    <div class="row" style="margin-top: 30px;">
	                        <div class="col-md-12 text-center">
	                            <h2>Step 2</h2>
	                            <p>Translate the Auction Sheet</p>
	                        </div>
	                    </div>

	                    <div class="row table-auction-sheet-translation">
                            <div class="col-md-6" style="border:0px;" valign="top">
                                <div class="map-container" style="display: flex;">

                                    <div style="width:587px !important;">
                                        <h3>Original</h3>

                                        <img v-if="data.IMAGES.length > 0" v-bind:src="data.IMAGES[0]"
	                                        style="width: 100%;" />
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                            	<div style="width1:587px !important; margin-left: 0px;">

                                    <h3>Translated</h3>

                                    <div id="translated-html-content-holder">
                                    	<table widtha="587" border="0" height="625"
                                    		style="background:white;">
                                            <tr class="tdgrey">
                                                <td width="11%">Lot <br/>Number</td>
                                                <td width="11%">Registration Year</td>
                                                <td colspan="2">Car Name</td>
                                                <td width="8%">Year</td>
                                                <td colspan="2">Package / Grade</td>
                                                <td colspan="2">Auction Grade</td>
                                            </tr>
                                            <tr class="trwhite">
                                                <td rowspan="3" contenteditable
                                                    data-key="lot" v-text="data.auctionSheet.LOT"></td>
                                                <td contenteditable
                                                    data-key="registrationYear" v-text="data.auctionSheet.registrationYear"></td>
                                                <td colspan="2" contenteditable
                                                    data-key="carName" v-text="data.auctionSheet.carName"></td>
                                                <td contenteditable
                                                    data-key="year" v-text="data.auctionSheet.YEAR"></td>
                                                <td colspan="2" contenteditable
                                                    data-key="package" v-html="data.auctionSheet.GRADE"></td>
                                                <td colspan="2" rowspan="2" style="font-size:16px"
                                                    contenteditable
                                                    data-key="grade" v-text="data.auctionSheet.RATE"></td>
                                            </tr>
                                            <tr>
                                                <td class="tdgrey">Month</td>
                                                <td width="12%" height="25" class="tdgrey">Engine (cc)</td>
                                                <td width="13%" class="tdgrey">Type</td>
                                                <td class="tdgrey">Fuel</td>
                                                <td colspan="2" class="tdgrey">Chasis</td>
                                            </tr>
                                            <tr class="trwhite">
                                                <td class="bold" data-key="month"
                                                    contenteditable v-text="data.auctionSheet.month"></td>
                                                <td contenteditable
                                                    data-key="eng_v" v-text="data.auctionSheet.ENG_V"></td>
                                                <td contenteditable
                                                    data-key="type" v-text="data.auctionSheet.KPP_TYPE"></td>
                                                <td contenteditable
                                                    data-key="fuel" v-text="data.auctionSheet.fuel"></td>
                                                <td colspan="2" contenteditable
                                                    data-key="chassis" v-html="data.auctionSheet.KUZOV"></td>
                                                <td width="6%" contenteditable
                                                    data-key="auctionGrade1" v-text="data.auctionSheet.auctionGrade1"></td>
                                                <td width="7%" contenteditable
                                                    data-key="auctionGrade2" v-text="data.auctionSheet.auctionGrade2"></td>
                                            </tr>
                                            <tr class="tdgrey">
                                                <td height="23" colspan="2">Auction Date</td>
                                                <td colspan="2">Mileage</td>
                                                <td>Doors</td>
                                                <td colspan="4">Sale Points</td>
                                            </tr>
                                            <tr class="trwhite">
                                                <td height="23" colspan="2" contenteditable
                                                    data-key="auction_date" v-text="data.auctionSheet.AUCTION_DATE"></td>
                                                <td colspan="2">
                                                    <span contenteditable
                                                      data-key="mileage" v-text="data.auctionSheet.MILEAGE"></span>
                                                    km
                                                </td>
                                                <td contenteditable
                                                    data-key="doors" v-text="data.auctionSheet.doors"></td>
                                                <td colspan="4" rowspan="5" valign="top" contenteditable
                                                    data-key="salesPoint" v-text="data.auctionSheet.salesPoint"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="tdgrey">Auction House</td>
                                                <td colspan="2" class="tdgrey">Color</td>
                                                <td class="tdgrey">Seats</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" class="bold" data-key="auction"
                                                    style="font-size:12px"
                                                    contenteditable v-text="data.auctionSheet.AUCTION"></td>
                                                <td colspan="2" class="bold" data-key="color"
                                                    contenteditable v-text="data.auctionSheet.COLOR"></td>
                                                <td class="bold" contenteditable
                                                    data-key="seats" v-text="data.auctionSheet.seats"></td>
                                            </tr>
                                            <tr>
                                                <td class="tdgrey">Shift</td>
                                                <td class="tdgrey">Air-Con</td>
                                                <td colspan="2" class="tdgrey">Color Code</td>
                                                <td class="tdgrey">&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td class="bold" data-key="shift"
                                                    contenteditable v-text="data.auctionSheet.shift"></td>
                                                <td class="bold" data-key="airConditioner"
                                                    contenteditable v-text="data.auctionSheet.airConditioner"></td>
                                                <td colspan="2" class="bold" data-key="colorCode"
                                                    contenteditable v-text="data.auctionSheet.colorCode"></td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" rowspan="2" style="border:0px;">

                                                    <table width="100%" border="0" cellpadding="0"
                                                           cellspacing="0">
                                                        <tr>
                                                            <td width="50%" class="tdgrey">Weight</td>
                                                            <td width="50%" class="tdgrey">Recycle Fee</td>
                                                        </tr>
                                                        <tr>
                                                            <td height="23" class="bold"><span
                                                                data-key="weight"
                                                                contenteditable v-text="data.auctionSheet.weight"></span>
                                                                kg
                                                            </td>
                                                            <td class="bold" data-key="recycleFee"
                                                                contenteditable v-text="data.auctionSheet.recycleFee"></td>
                                                        </tr>
                                                    </table>
                                                </td>
                                                <td height="25" colspan="4" class="tdgrey">Options</td>
                                            </tr>
                                            <tr>
                                                <td height="23" colspan="4" class="bold" data-key="options"
                                                    contenteditable v-text="data.auctionSheet.options"></td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" rowspan="2" class="tdgrey">Comments</td>
                                                <td colspan="4" class="tdgrey">Chasis</td>
                                            </tr>
                                            <tr>
                                                <td width="1%" class="tdgrey"></td>
                                                <td height="23" colspan="3" class="bold" data-key="chassis"
                                                    contenteditable v-text="data.auctionSheet.KUZOV"></td>
                                            </tr>


                                            <tr>
                                                <td height="277" colspan="9">
                                                    <table width="100%" height="273" border="0"
                                                           cellspacing="0">
                                                        <tr>
                                                            <td width="55%" height="273" valign="top"
                                                                style="border:0px; position: relative;" border="0">
                                                                <table width="100%" border="0"
                                                                       cellpadding="0" cellspacing="0">
                                                                    <tr class="bold">
                                                                        <td style="border:0px;">
                                                                            <div align="left"
                                                                                 style="height:240px"
                                                                                 contenteditable
                                                                                 data-key="comments" v-text="data.auctionSheet.comments"></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>

                                                                <div style="position: absolute; bottom: 0px;">
                                                                    <div align="left">
                                                                        <span style=" font-size:12;"> Translated into English by: </span>
                                                                    </div>

                                                                    <div align="right">
                                                                        <span style=" font-size:12;">
                                                                            <img src="../../assets/images/logo.png"
                                                                                 style="width: 175px; min-width: auto !important; min-height: auto !important; height: auto !important; max-width: initial !important;" />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td width="45%" valign="top"
                                                                style="height: 270px; border: 0px; border-left: 1px solid; padding: 15px;"
                                                                class="chassis">

                                                                <img id="car-body"
                                                                	style="height: 270px;"
                                                                	v-bind:src="carBody" />
                                                            </td>

                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" style="margin-top: 50px;">
                        	<div class="col-md-12">
                        		<button type="button" class="btn btn-primary" v-on:click="updateAuctionSheet">
		                            Update Auction Sheet
		                            <i class="fa fa-spinner fa-spin" v-if="updating"></i>
		                        </button>

		                        <a v-bind:href="$homeURL + '/carDetail/' + data.CAR_ID" class="btn btn-link" target="_blank">
		                        	View Car
		                        </a>
                        	</div>
                        </div>
				    </template>

				</div>
			</div>
		</div>

	</div>
</template>

<script>

	import jQuery from "jquery"
	import axios from "axios"
    import swal from "sweetalert2"

    import "../../assets/cropper/cropper.css"
    import Cropper from "../../assets/cropper/cropper.js"
    import domtoimage from "../../assets/html2canvas/dom-to-image.js"

	export default {
		name: "AuctionSheetTranslateComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				loading: false,
				data: null,
				cropper: null,
				updating: false,
				bodyCropped: false,
				carBody: ""
			}
		},

		methods: {

			updateAuctionSheet: function () {
				const self = this
				this.updating = true

				const node = document.getElementById("translated-html-content-holder")
			    const options = {
			        quality: 1
			    }

			    domtoimage.toPng(node, options).then(async function (dataUrl) {

			    	var contentEditables = document.querySelectorAll("[contenteditable]");
			        var data = [];
			        for (var a = 0; a < contentEditables.length; a++) {
			            var key = contentEditables[a].getAttribute("data-key");
			            var value = contentEditables[a].innerHTML;

			            data.push({
			                "key": key,
			                "value": value
			            });
			        }

			    	const formData = new FormData()
			    	formData.append("data", JSON.stringify(data))
	        		formData.append("id", self.id)
	        		formData.append("imageData", dataUrl)

	        		try {
	        			const response = await axios.post(
					        self.$apiURL + "/admin/requests/auctionSheet/save",
					        formData,
					        {
					            headers: self.$headers
					        }
					    )
					 
					    if (response.data.status == "success") {
					    	swal.fire("Translation", response.data.message, "success")
					    } else {
					        swal.fire("Error", response.data.message, "error")
					    }
					} catch (error) {
						if (error?.response?.status == 401) {
							swal.fire("Error", error.response.data.message || "Unauthorized", "error")
						}
					} finally {
                        self.updating = false
                    }
			    })
			},

			cropCarBody: async function () {
	            var imageData = this.cropper.getCroppedCanvas().toDataURL("image/png")
                document.getElementById("car-body").setAttribute("src", imageData)

                const formData = new FormData()
        		formData.append("id", this.id)
        		formData.append("imageData", imageData)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/requests/auctionSheet/saveCarBody",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	//
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				}
			},

			getData: async function () {
        		const self = this
        		this.loading = true

        		const formData = new FormData()
        		formData.append("id", this.id)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/requests/auctionSheet/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.bodyCropped = response.data.bodyCropped
				    	this.carBody = response.data.carBody

				    	setTimeout(function () {
				    		const image = document.getElementById("original-auction-sheet")
				    		self.cropper = new Cropper(image, {
				                checkCrossOrigin: false,
				                zoomable: false,
				                autoCropArea: 0.4
				            })

				            image.addEventListener('cropend', function (event) {
		                        self.cropCarBody()
		                    })

				            setTimeout(function () {
		                        self.cropper.move(-145, -115)
		                        if (!self.bodyCropped) {
			                        self.cropCarBody()
			                    }
		                    }, 1000)
				    	}, 100)
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
                    this.loading = false
                }
			},
		},

		mounted() {
        	this.getData()
            document.title = "Translate Auction Sheet"
        }
	}
</script>

<style scoped>
    .table-auction-sheet-translation p,
    .table-auction-sheet-translation td {
        text-align: center;
        border: 1px solid #000;
        font-family: Arial, Helvetica, sans-serif;
    }

    .tdgrey {
        background-color: #dbdbdb;
        height: 4px;
        font-size: 11px !important;
    }

    .trwhite td, .bold {
        font-size: 12px !important;
        font-weight: bold;
    }

    .auc_btn {
        font-size: 13px !important;
        text-decoration: none;
        font-weight: bold;
        color: red;
        border: 1px solid;
        padding: 5px;
        background: white;
    }

    .auc_btn:hover {
        color: white;
        background: black;
    }

    .map-container {
        position: relative;
        display: inline-block;
    }

    .map-watermark {
        position: absolute;
        width: 142% !important;
        height: 25% !important;
        opacity: .3;
        top: 25%;
        left: 7%;
        transform: rotate3d(1, 1, 2, -53deg);
    }

    .map-title {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 18px !important;
        font-weight: bold;
        color: #222;
        width: 40%;
        background: rgba(255, 255, 255, 0.60);
        border-bottom: 3px solid #0097e2;
        padding: 3px 0 3px 10px;
    }
</style>