<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Edit FAQ</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Edit FAQ</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row">
            <div class="col-sm-12">
                <div class="white-box">
					<form v-on:submit.prevent="editData" v-if="data != null">
                		<div class="form-group">
							<label class="form-label">Question</label>
							<input type="text" name="question" placeholder="Enter Question" class="form-control" v-model="data.question" />
						</div>

						<div class="form-group">
							<label class="form-label">Answer</label>
							<textarea type="text" name="answer" placeholder="Enter Answer" class="form-control" v-model="data.answer"></textarea>
						</div>

						<div class="form-group">
							<label class="form-label">Order Number</label>
							<input type="number" name="orderNumber" placeholder="Enter Order Number" class="form-control" v-model="data.orderNumber" />
						</div>

						<button type="submit" name="submit" class="btn btn-warning" style="color: black;">
							Edit FAQ
							<i class="fa fa-spinner fa-spin" v-if="isUpdating"></i>
						</button>
                	</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "EditFAQComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				data: null,
				loading: false,
				isUpdating: false
			}
		},

		methods: {

			async editData() {
				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)
				this.isUpdating = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/faqs/update",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Edit FAQ", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isUpdating = false
				}
			},

			async getData() {
				const formData = new FormData()
				formData.append("id", this.id)
				this.loading = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/faqs/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Edit FAQ"
		}
	}
</script>