<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Awarded Cars</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Awarded Cars</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group mb-4">
                            <label class="col-md-12 p-0">Search</label>
                            <div class="col-md-12 border-bottom p-0">
                                <input type="text" name="search" v-on:keypress="onKeyPressSearch" v-model="search" class="form-control p-0 border-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Column -->
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title">Awarded Cars (<span v-text="data.length"></span>)</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <div class="table-responsive">
                        <table v-if="!loading" class="table text-nowrap" id="cars-data">
                        	<thead>
                                <tr v-if="$isMobile()">
                                    <th></th>
                                </tr>

                                <tr v-else>
                                	<th>LOT</th>
                                	<th>IMAGES</th>
                                	<th>DATE</th>
                                	<th>YEAR</th>
                                	<th>NAME</th>
                                	<th>Chassis</th>
                                	<th>Package</th>
                                	<th>Mileage</th>
                                	<th>Displace</th>
                                	<th>Transmission</th>
                                	<th>Grade</th>
                                	<th>Color</th>
                                	<th>Starting Price</th>
                                	<th>Bid Price</th>
                                	<th>Awarded Price</th>
                                	<th>User</th>
                                	<th>Actions</th>
                                </tr>
                            </thead>

                            <tbody>
	                            <template v-for="(d, index) in data">
	                            	<tr v-if="$isMobile()">
	                            		<th>
	                            			<table>
	                            				<tr>
													<th class="border-top-0">LOT</th>
													<td v-text="d.auctionCar.LOT"></td>
												</tr>

												<tr>
													<th class="border-top-0">Images</th>
													<td>
														<a v-bind:href="$homeURL + '/carDetail/' + d.auctionCar.CAR_ID" target="_blank">
															<template v-for="(img, index) in 3">
																<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" />
															</template>
														</a>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Date</th>
													<td v-text="d.auctionCar.AUCTION_DATE"></td>
												</tr>

												<tr>
													<th class="border-top-0">Year</th>
													<td v-text="d.auctionCar.YEAR"></td>
												</tr>

												<tr>
													<th class="border-top-0">Name</th>
													<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
												</tr>

												<tr>
													<th class="border-top-0">Chassis</th>
													<td v-text="d.auctionCar.KUZOV"></td>
												</tr>

												<tr>
													<th class="border-top-0">Package</th>
													<td v-html="d.auctionCar.GRADE"></td>
												</tr>

												<tr>
													<th class="border-top-0">Mileage</th>
													<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Displace</th>
													<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
												</tr>

												<tr>
													<th class="border-top-0">Transmission</th>
													<td v-text="d.auctionCar.KPP"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">Grade</th>
													<td v-text="d.auctionCar.RATE"></td>
												</tr>

												<tr>
													<th class="border-top-0">Color</th>
													<td v-text="d.auctionCar.COLOR"></td>
												</tr>
												
												<tr>
													<th class="border-top-0">Starting Price</th>
													<td v-html="'&yen;' + d.auctionCar.START"></td>
												</tr>

												<tr>
													<th class="border-top-0">Bid Price</th>
													<td v-html="'&yen;' + d.bidAmount"></td>
												</tr>

												<tr>
													<th class="border-top-0">Awarded Price</th>
													<td v-html="'&yen;' + d.awardedPrice"></td>
												</tr>

												<tr>
													<th class="border-top-0">User</th>
													<td>
														<router-link v-text="d.userName + ' | ' + d.userEmail" v-bind:to="'/users/edit/' + d.userId"></router-link>
													</td>
												</tr>

												<tr>
													<th class="border-top-0">Actions</th>
													<td>
														<router-link v-bind:to="'/awardedCars/tracking/' + d.auctionCarId">
															Tracking
														</router-link>
													</td>
												</tr>
	                            			</table>
	                            		</th>
	                            	</tr>

	                            	<tr v-else>

	                            		<td v-text="d.auctionCar.LOT"></td>

	                            		<td>
											<a v-bind:href="$homeURL + '/carDetail/' + d.auctionCar.CAR_ID"
												target="_blank">
												<template v-for="(img, index) in 3">
													<img v-if="index < d.auctionCar.IMAGES.length" v-bind:src="d.auctionCar.IMAGES[index].replaceAll('h=50', 'w=320')" />
												</template>
											</a>
										</td>

										<td v-text="d.auctionCar.AUCTION_DATE"></td>
										<td v-text="d.auctionCar.YEAR"></td>
										<td v-text="d.auctionCar.MARKA_NAME + ' ' + d.auctionCar.MODEL_NAME"></td>
										<td v-text="d.auctionCar.KUZOV"></td>
										<td v-html="d.auctionCar.GRADE"></td>
										<td v-text="d.auctionCar.MILEAGE + ' km'"></td>
										<td v-text="d.auctionCar.ENG_V + ' cc'"></td>
										<td v-text="d.auctionCar.KPP"></td>
										<td v-text="d.auctionCar.RATE"></td>
										<td v-text="d.auctionCar.COLOR"></td>
										<td v-html="'&yen;' + d.auctionCar.START"></td>
										<td v-html="'&yen;' + d.bidAmount"></td>
										<td v-html="'&yen;' + d.awardedPrice"></td>
										<td>
											<router-link v-text="d.userName + ' | ' + d.userEmail" v-bind:to="'/users/edit/' + d.userId"></router-link>
										</td>
										<td>
											<router-link v-bind:to="'/awardedCars/tracking/' + d.auctionCarId">
												Tracking
											</router-link>
										</td>
	                            	</tr>
								</template>
							</tbody>
                        </table>
                    </div>

                    <div v-if="pages > 0" id="pagination">
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
									<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
								</li>
							</ul>
						</nav>
					</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"
    import jQuery from "jquery"

    import "../assets/datatable/datatables.css"
    import "../assets/datatable/datatables.min.js"

	export default {
		name: "AwardedCarsComponent",

		data() {
			return {
				loading: false,
				data: [],
				search: "",
				dataTable: null,
				total: 0,
				pages: 0,
				pageNumber: this.$route.query.page || 1
			}
		},

		methods: {

			onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

			paginate: function (page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)

				this.getData()
			},

			getData: async function () {
        		const self = this
        		this.pages = 0

        		this.loading = true
        		this.data = []

        		if (this.dataTable != null) {
	            	this.dataTable.destroy()
	            }

        		const formData = new FormData()
        		formData.append("search", this.search)
        		formData.append("page", this.pageNumber)

        		try {
        			const response = await axios.post(
				        this.$apiURL + "/admin/auctionCars/awarded",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )

				    this.loading = false
				 
				    if (response.data.status == "success") {
				    	this.data = response.data.data
				    	this.pages = response.data.pages
						this.pageNumber = response.data.pageNumber
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
        		} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
			    		self.initialize()
			    	}, 500)
				}
			},

			initialize() {

				if (this.$isMobile()) {
					this.dataTable = jQuery("#cars-data").DataTable({
		            	ordering: false,
	                    pageLength: 1
		            })
				} else {
					this.dataTable = jQuery("#cars-data").DataTable({
		            	ordering: false
		            })
				}
	        },
		},

		mounted() {
        	this.getData()
        	document.title = "Awarded Cars"
        }
	}
</script>

<style scoped>
	#cars-data img {
		height: 100px;
		display: inline-block;
	}
</style>