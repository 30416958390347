import { createApp } from 'vue'
import App from './App.vue'

import { createRouter, createWebHistory } from 'vue-router'
import VueMobileDetection from "vue-mobile-detection"

import store from "./vuex/store"

const app = createApp(App)
app.use(VueMobileDetection)

import LoginComponent from "./components/LoginComponent.vue"
import DashboardComponent from "./components/DashboardComponent.vue"
import AwardedCarsComponent from "./components/AwardedCarsComponent.vue"

import BidsComponent from "./components/Bids/BidsComponent.vue"

import UsersComponent from "./components/Users/UsersComponent.vue"
import EditUserComponent from "./components/Users/EditUserComponent.vue"
import UserLogsComponent from "./components/Users/UserLogsComponent.vue"

import RequestedImagesComponent from "./components/Requests/RequestedImagesComponent.vue"
import AuctionSheetsComponent from "./components/Requests/AuctionSheetsComponent.vue"
import AuctionSheetTranslateComponent from "./components/Requests/AuctionSheetTranslateComponent.vue"

import TransactionsComponent from "./components/Transactions/TransactionsComponent.vue"
import AddTransactionComponent from "./components/Transactions/AddTransactionComponent.vue"
import EditTransactionComponent from "./components/Transactions/EditTransactionComponent.vue"
import TransactionLogsComponent from "./components/Transactions/TransactionLogsComponent.vue"

import TrackingComponent from "./components/Tracking/TrackingComponent.vue"
import UserTrackingComponent from "./components/UserTrackingComponent.vue"

import AuctionCarsComponent from "./components/AuctionCars/AuctionCarsComponent.vue"
import UserCarsComponent from "./components/AuctionCars/UserCarsComponent.vue"
import EditAuctionCarComponent from "./components/AuctionCars/EditAuctionCarComponent.vue"

import MailsComponent from "./components/MailsComponent.vue"
import InvoicesComponent from "./components/Invoices/InvoicesComponent.vue"
import InvoiceEditComponent from "./components/Invoices/InvoiceEditComponent.vue"

import PartsComponent from "./components/Parts/PartsComponent.vue"
import EditPartComponent from "./components/Parts/EditPartComponent.vue"

import PaymentsComponent from "./components/PaymentsComponent.vue"
import OrdersComponent from "./components/Orders/OrdersComponent.vue"
import PagesComponent from "./components/Pages/PagesComponent.vue"
import EditPageComponent from "./components/Pages/EditPageComponent.vue"

import InvestorPanelComponent from "./components/InvestorPanel/InvestorPanelComponent.vue"
import CarOrdersComponent from "./components/InvestorPanel/CarOrdersComponent.vue"
import ViewChatComponent from "./components/InvestorPanel/ViewChatComponent.vue"
import PayeesComponent from "./components/Payees/PayeesComponent.vue"
import EditPayeeComponent from "./components/Payees/EditPayeeComponent.vue"
import SMSComponent from "./components/SMSComponent.vue"
import TestimonialsComponent from "./components/Testimonials/TestimonialsComponent.vue"
import EditTestimonialComponent from "./components/Testimonials/EditTestimonialComponent.vue"
import FAQsComponent from "./components/FAQs/FAQsComponent.vue"
import EditFAQComponent from "./components/FAQs/EditFAQComponent.vue"
import ConfigurationsComponent from "./components/Configurations/ConfigurationsComponent.vue"
import EditConfigurationComponent from "./components/Configurations/EditConfigurationComponent.vue"
import CountriesComponent from "./components/Countries/CountriesComponent.vue"
import EditCountryComponent from "./components/Countries/EditCountryComponent.vue"
import MessagesComponent from "./components/MessagesComponent.vue"

const routes = [
    { path: "/chats", component: MessagesComponent },

    { path: "/countries/edit/:id", component: EditCountryComponent },
    { path: "/countries", component: CountriesComponent },

    { path: "/configurations/edit/:id", component: EditConfigurationComponent },
    { path: "/configurations", component: ConfigurationsComponent },

    { path: "/faqs/edit/:id", component: EditFAQComponent },
    { path: "/faqs", component: FAQsComponent },

    { path: "/testimonials/edit/:id", component: EditTestimonialComponent },
    { path: "/testimonials", component: TestimonialsComponent },
    { path: "/sms", component: SMSComponent },

    { path: "/payees/edit/:id", component: EditPayeeComponent },
    { path: "/payees", component: PayeesComponent},

    { path: "/chat/:user1/:user2", component: ViewChatComponent },
    { path: "/carOrders/:id", component: CarOrdersComponent },
    { path: "/investorPanel", component: InvestorPanelComponent },

    { path: "/pages/edit/:id", component: EditPageComponent },
    { path: "/pages", component: PagesComponent },
    { path: "/payments", component: PaymentsComponent },
    { path: "/orders", component: OrdersComponent },

    { path: "/parts/edit/:id", component: EditPartComponent },
    { path: "/parts", component: PartsComponent },

    { path: "/invoices/edit/:id", component: InvoiceEditComponent },
    { path: "/invoices", component: InvoicesComponent },

    { path: "/userCars", component: UserCarsComponent },
    { path: "/auctionCars/edit/:carId", component: EditAuctionCarComponent },
    { path: "/auctionCars", component: AuctionCarsComponent },
    { path: "/userTracking", component: UserTrackingComponent },

    { path: "/transactions/logs/:id", component: TransactionLogsComponent },
    { path: "/transactions/edit/:id", component: EditTransactionComponent },
    { path: "/transactions/add", component: AddTransactionComponent },
    { path: "/transactions", component: TransactionsComponent },

    { path: "/requested/auctionSheets/translate/:id", component: AuctionSheetTranslateComponent },
    { path: "/requested/auctionSheets", component: AuctionSheetsComponent },
    { path: "/requested/images", component: RequestedImagesComponent },
    
    { path: "/awardedCars/tracking/:id", component: TrackingComponent },
    { path: "/awardedCars", component: AwardedCarsComponent },

    { path: "/mails", component: MailsComponent },
    { path: "/bids", component: BidsComponent },
    
    { path: "/users/logs/:id", component: UserLogsComponent },
    { path: "/users/edit/:id", component: EditUserComponent },
    { path: "/users", component: UsersComponent },
    
    { path: "/", component: DashboardComponent },
    { path: "/login", component: LoginComponent }
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
})

app.use(router)

app.config.globalProperties.$apiBaseUrl = "https://betaapi.usstokyo.com"
app.config.globalProperties.$apiURL = "https://betaapi.usstokyo.com/api"
app.config.globalProperties.$mainURL = "https://betaadmin.usstokyo.com"
app.config.globalProperties.$homeURL = "https://beta.usstokyo.com"
app.config.globalProperties.$nodeURL = "https://node.usstokyo.com:8000"
// app.config.globalProperties.$nodeURL = "http://node.usstokyo.com:3000"

// app.config.globalProperties.$apiBaseUrl = "http://localhost:8888/ussglobal/api"
// app.config.globalProperties.$apiURL = "http://localhost:8888/ussglobal/api/api"
// app.config.globalProperties.$mainURL = "http://localhost:8081"
// app.config.globalProperties.$homeURL = "http://localhost:8080"
// app.config.globalProperties.$nodeURL = "http://localhost:3000"

app.config.globalProperties.$accessToken = "adminAccessToken"
app.config.globalProperties.$headers = {
    Authorization: "Bearer " + localStorage.getItem("adminAccessToken")
}

app.mixin({

    computed: {
        user() {
            return store.getters.getUser
        }
    },

    methods: {
        prependArray(value, array) {
            const newArray = array.slice()
            newArray.unshift(value)
            return newArray
        },

        getImage(imageArr, index) {
            if (index < imageArr.length) {
                return imageArr[index].replace("h=50", "w=320")
            }
            return ""
        },
        
        hasPermission: function (action) {
            if (this.user.role == "superAdmin"
                || this.user.role == "developer") {
                return true
            }
            for (let a = 0; a < this.user.permissions.length; a++) {
                if (this.user.permissions[a].action == action) {
                    return true
                }
            }
            return false
        },

        addOrUpdateURLParam: function (key, value) {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.set(key, value)
            const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
            history.pushState(null, '', newRelativePathQuery)
        },

        getHighResImage: function (img) {
            return img.replaceAll("h=50", "w=320")
        }
    }
})

app.mount('#app')
