<template>
    <div class="container-fluid">

        <div class="row">
            <!-- Column -->
            <div class="col-md-12">
                <div class="white-box">
                    <div class="card">
                        <div class="card-body">
                            <div class="form-group mb-4">
                                <label class="col-md-12 p-0">Search</label>
                                <div class="col-md-12 border-bottom p-0">
                                    <input type="text" v-model="search" class="form-control p-0 border-0" v-on:keypress="onKeyPressSearch" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
        	<div class="col-md-12">
        		<div class="white-box">
        			<div class="text-center" v-if="loading">
        				<div class="spinner-border"></div>
        			</div>

        			<div class="table-responsive">
        				<table v-if="!loading" class="table table-bordered" id="cars-data">
        					<thead>
        						<tr v-if="$isMobile()">
        							<th></th>
        						</tr>

        						<tr v-else>
        							<th>Car</th>
        							<th>Images</th>
                                    <th>User</th>
                                    <th>Status</th>
                                    <th v-if="user.role == 'superAdmin' || user.role == 'developer'">Actions</th>
        						</tr>
        					</thead>

        					<tbody>

        						<template v-for="(d, index) in data">
        							<tr v-if="$isMobile()">
        								<th>
        									<table>
        										<tr>
        											<th class="border-top-0">CAR_ID</th>
        											<td>
				        								<a v-bind:href="$homeURL + '/carDetail/' + d.CAR_ID" v-text="d.CAR_ID"></a>

				        								<br /><br />

				        								<router-link v-bind:to="'/auctionCars/edit/' + d.CAR_ID">Edit</router-link>
				        							</td>
				        						</tr>

			        							<tr>
			        								<th class="border-top-0">LOT</th>
			        								<td v-text="d.LOT"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AUCTION_DATE</th>
			        								<td v-text="d.AUCTION_DATE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AUCTION</th>
			        								<td v-text="d.AUCTION"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MARKA_ID</th>
			        								<td v-text="d.MARKA_ID"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MODEL_ID</th>
			        								<td v-text="d.MODEL_ID"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MARKA_NAME</th>
			        								<td v-text="d.MARKA_NAME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MODEL_NAME</th>
			        								<td v-text="d.MODEL_NAME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">YEAR</th>
			        								<td v-text="d.YEAR"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">ENG_V</th>
			        								<td v-text="d.ENG_V"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">PW</th>
			        								<td v-text="d.PW"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KUZOV</th>
			        								<td v-html="d.KUZOV"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">GRADE</th>
			        								<td v-text="d.GRADE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">COLOR</th>
			        								<td v-text="d.COLOR"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KPP</th>
			        								<td v-text="d.KPP"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">KPP_TYPE</th>
			        								<td v-text="d.KPP_TYPE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">PRIV</th>
			        								<td v-text="d.PRIV"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">MILEAGE</th>
			        								<td v-text="d.MILEAGE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">EQUIP</th>
			        								<td v-text="d.EQUIP"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">RATE</th>
			        								<td v-text="d.RATE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">START</th>
			        								<td v-text="d.START"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">FINISH</th>
			        								<td v-text="d.FINISH"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">STATUS</th>
			        								<td v-text="d.STATUS"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">TIME</th>
			        								<td v-text="d.TIME"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AVG_PRICE</th>
			        								<td v-text="d.AVG_PRICE"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">AVG_STRING</th>
			        								<td v-text="d.AVG_STRING"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">IMAGES</th>
			        								<td>
				        								<template v-for="a in 3">
				        									<img v-if="a < d.IMAGES.length" v-bind:src="d.IMAGES[a]" style="width: 100px; display: inline-block;" />
				        								</template>
				        							</td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">SERIAL</th>
			        								<td v-html="d.SERIAL"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">INFO</th>
			        								<td v-html="d.INFO"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">auctionSheet</th>
			        								<td v-html="d.auctionSheet"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">awardedTo</th>
			        								<td v-html="d.awardedTo"></td>
			        							</tr>

			        							<tr>
			        								<th class="border-top-0">type</th>
			        								<td v-html="d.type"></td>
			        							</tr>
        									</table>
        								</th>
        							</tr>

        							<tr v-else>
	        							<td>
                                            <span v-text="'Name: ' + d.MARKA_NAME + ' ' + d.MODEL_NAME + ' - ' + d.YEAR"></span> <br />
											<span v-text="'Engine: ' + d.ENG_V"></span> <br />
											<span v-text="'Transmission: ' + d.KPP"></span> <br />
											<span v-text="'Grade: ' + d.RATE"></span> <br />
											<span v-text="'Package: ' + d.GRADE"></span> <br />
											<span v-text="'Chassis: ' + d.KUZOV"></span> <br />
											<span v-text="'Color: ' + d.COLOR"></span> <br />
											<span v-text="'Mileage: ' + d.MILEAGE"></span> <br />
											<span v-text="'Starting: &yen;' + d.START"></span> <br />
											<span v-html="'Description: ' + d.INFO"></span> <br />
                                        </td>
	        							<td>
	        								<template v-for="img in d.IMAGES">
	        									<img v-bind:src="img" style="width: 100px; display: inline-block;" />
	        								</template>
	        							</td>

										<td>
											<router-link v-if="d.createdBy != 0" v-bind:to="'/users/edit/' + d.createdBy" v-text="d.name + ' | ' + d.email"></router-link>
										</td>

										<td v-text="d.carStatus" style="text-transform: uppercase;"></td>

										<td v-if="user.role == 'superAdmin' || user.role == 'developer'">
											<button type="button" v-if="d.carStatus == 'submitted'" class="btn btn-primary" v-on:click="approveCar(d.id)">Approve</button>
										</td>
        							</tr>
        						</template>
        					</tbody>
        				</table>
        			</div>

        			<nav v-if="pages > 0">
        				<ul class="pagination">
        					<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
        						<a class="page-link" href="javascript:void(0)" v-on:click="paginate(page)" v-text="page"></a>
        					</li>
        				</ul>
        			</nav>
        		</div>
        	</div>
        </div>
    </div>
</template>

<script>

    import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../../assets/datatable/datatables.css"
    import "../../assets/datatable/datatables.min.js"

    export default {
        name: "UserCarsComponent",

        data() {
            return {
                search: "",
                loading: false,
				data: [],
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null
            }
        },

        methods: {

        	approveCar(id) {
        		const self = this
        		swal.fire({
					title: 'Approve the car ?',
					text: "The car will start displaying in auction's list.",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, approve!'
				}).then(async function (result) {
					if (result.isConfirmed) {

						$(".loading").show()

						const formData = new FormData()
						formData.append("id", id)

						try {
							const response = await axios.post(
								self.$apiURL + "/admin/auctionCars/approve",
								formData,
								{
									headers: self.$headers
								}
							)

							if (response.data.status == "success") {
								for (let a = 0; a < self.data.length; a++) {
									if (self.data[a].id == id) {
										self.data[a].carStatus = "approved"
										break
									}
								}
							} else {
								swal.fire("Error", response.data.message, "error")
							}
						} catch (error) {
							if (error?.response?.status == 401) {
								swal.fire("Error", error.response.data.message || "Unauthorized", "error")
							}
						} finally {
							$(".loading").hide()
						}
					}
				})
        	},

            onKeyPressSearch() {
				if (event.keyCode == 13) {
					this.pageNumber = 1
					this.getData()
				}
			},

            paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				this.pages = 0

				this.loading = true
				this.data = []

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				const formData = new FormData()
				formData.append("page", this.pageNumber)
				formData.append("search", this.search)

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/auctionCars/userUploaded",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
            },

            initialize() {
                if (this.$isMobile()) {
                    this.dataTable = jQuery("#cars-data").DataTable({
                        ordering: false,
                        pageLength: 1
                    }) 
                } else {
                    this.dataTable = jQuery("#cars-data").DataTable({
                        ordering: false
                    })
                }
            }
        },

        mounted() {
			this.getData()
			document.title = "User Cars"
		}
    }
</script>