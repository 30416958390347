<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Edit Transaction</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Edit Transaction</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title" style="margin-bottom: 30px;">Edit Transaction</h3>

                    <div class="text-center" v-if="loading">
				        <div class="spinner-border">
				            <span class="sr-only">Loading...</span>
				        </div>
				    </div>

                    <form v-on:submit.prevent="editTransaction" v-if="transaction != null">
                    	<div class="form-group">
                    		<label>Enter Email</label>
                    		<input type="email" name="email" v-model="transaction.email" class="form-control" />
                    	</div>

                    	<div class="form-group">
                    		<label>Currency</label>
                    		<input type="text" name="currency" v-model="transaction.currency" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>1 <span v-text="transaction.currency"></span> in USD</label>
                    		<input type="text" name="rateInUSD" v-model="transaction.rateInUSD" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Type</label>
                    		<select name="type" class="form-control" v-model="transaction.type" required>
                    			<option value="securityDeposit">Security Deposit</option>
                    			<option value="carPayment">Car Payment</option>
                    			<option value="advanceCarPayment">Advance Car Payment</option>
                    		</select>
                    	</div>

                    	<div class="form-group">
                    		<label>Payment Method</label>
                    		<select name="paymentMethod" class="form-control" v-model="transaction.paymentMethod" required>
                    			<option value="bank">Bank</option>
                    			<option value="cash">Cash</option>
                    			<option value="cheque">Cheque</option>
                    			<option value="paypal">PayPal</option>
                    		</select>
                    	</div>

                    	<div class="form-group" v-if="transaction.paymentMethod == 'bank'">
                    		<label>Select Bank</label>
                    		<select name="bank" class="form-control" required>
                    			<option v-for="(b, index) in $banks" v-bind:value="JSON.stringify(b)" v-text="b.accountTitle + ' ' + b.accountNumber" v-bind:selected="b.id == JSON.parse(transaction.bank).id"></option>
                    		</select>
                    	</div>

                    	<div class="form-group" v-if="transaction.paymentMethod == 'cheque'">
                    		<label>Enter cheque number</label>
                    		<input type="text" name="cheque" v-model="transaction.cheque" class="form-control" />
                    	</div>

                    	<div class="form-group">
                    		<label>Amount</label>
                    		<input type="number" name="amount" v-model="transaction.amount" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Description</label>
                    		<textarea name="description" v-html="transaction.description" class="form-control"></textarea>
                    	</div>

                    	<div class="form-group">
                    		<label>Banking Fee</label>
                    		<input type="number" name="bankingFee" v-model="transaction.bankingFee" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Shipping Charges</label>
                    		<input type="number" name="shippingCharges" v-model="transaction.shippingCharges" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Taxes</label>
                    		<input type="number" v-model="transaction.taxes" name="taxes" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>Shipping Discount</label>
                    		<input type="number" name="shippingDiscount" v-model="transaction.shippingDiscount" class="form-control" required />
                    	</div>

                    	<div class="form-group">
                    		<label>VAT</label>
                    		<input type="number" name="vat" v-model="transaction.vat" class="form-control" required />
                    	</div>

                    	<button type="submit" class="btn btn-primary">
                    		Update Transaction

                    		<i class="fa fa-spinner fa-spin" v-if="isUpdating"></i>
                    	</button>
                    </form>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
    import swal from "sweetalert2"

	export default {
		name: "EditTransactionComponent",

		data() {
			return {
				isUpdating: false,
				id: this.$route.params.id || "",
				loading: false,
				transaction: null
			}
		},

		methods: {

        	editTransaction: async function () {
        		this.isUpdating = true

        		const form = event.target
        		const formData = new FormData(form)
        		formData.append("id", this.id)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/transactions/update",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	swal.fire("Transaction", response.data.message, "success")
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isUpdating = false
				}
			},

			getData: async function () {
				this.loading = true

        		const formData = new FormData()
        		formData.append("id", this.id)

        		try {
				    const response = await axios.post(
				        this.$apiURL + "/admin/transactions/fetch",
				        formData,
				        {
				            headers: this.$headers
				        }
				    )
				 
				    if (response.data.status == "success") {
				    	this.transaction = response.data.data
				    } else {
				        swal.fire("Error", response.data.message, "error")
				    }
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
        },

        mounted() {
        	this.getData()
        	document.title = "Edit Transaction"
        }
	}
</script>