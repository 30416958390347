<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">User Logs</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">User Logs</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <h3 class="box-title"></h3>

                    <div v-if="loading" class="text-center">
                    	<div class="spinner-border"></div>
                    </div>

                    <div class="table-responsive">
                    	<table v-if="!loading" class="table text-nowrap" id="data">
                    		<thead>
                    			<tr v-if="$isMobile()">
                    				<th></th>
                    			</tr>

                    			<tr v-else>
                    				<th>Action</th>
                    				<th>Logged By</th>
                    				<th>Time</th>
                    			</tr>
                    		</thead>

                    		<tbody>
                    			<template v-for="(d, index) in data">
                    				<tr v-if="$isMobile()">
                    					<td>
                    						<table>
                    							<tr>
                    								<th class="border-top-0">Action</th>
                    								<td v-text="d.action"></td>
                    							</tr>

                    							<tr>
                    								<th class="border-top-0">Logged By</th>
                    								<td>
			                    						<router-link v-bind:to="'/users/edit/' + d.loggedBy" v-text="d.loggedByEmail"></router-link>
			                    					</td>
                    							</tr>

                    							<tr>
                    								<th class="border-top-0">Time</th>
                    								<td v-text="d.created_at"></td>
                    							</tr>
                    						</table>
                    					</td>
                    				</tr>

                    				<tr v-else>
                    					<td v-text="d.action"></td>
                    					<td>
                    						<router-link v-bind:to="'/users/edit/' + d.loggedBy" v-text="d.loggedByEmail"></router-link>
                    					</td>
                    					<td v-text="d.created_at"></td>
                    				</tr>
                    			</template>
                    		</tbody>
                    	</table>
                    </div>

                    <nav v-if="pages > 0">
                    	<ul class="pagination">
                    		<li v-for="page in pages" v-bind:class="'page-item ' + (page == pageNumber ? 'active' : '')">
                    			<a href="javascript:void(0)" v-on:click.prevent="paginate(page)" class="page-link" v-text="page"></a>
                    		</li>
                    	</ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	export default {
		name: "UserLogsComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				data: [],
				loading: false,
				pages: 0,
				pageNumber: this.$route.query.page || 1,
				dataTable: null
			}
		},

		methods: {
			paginate(page) {
				this.pageNumber = page
				this.addOrUpdateURLParam("page", page)
				this.getData()
			},

			async getData() {
				const self = this
				const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

				const formData = new FormData()
				formData.append("id", this.id)
				formData.append("page", this.pageNumber)
				formData.append("timezone", timezone)

				this.loading = true

				if (this.dataTable != null) {
					this.dataTable.destroy()
				}

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/users/logs",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.data = response.data.data
						this.pages = response.data.pages
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false

					setTimeout(function () {
						self.initialize()
					}, 500)
				}
			},

			initialize() {
				if (this.$isMobile()) {
					this.dataTable = jQuery("#data").DataTable({
						ordering: false,
						pageLength: 1
					})
				} else {
					this.dataTable = jQuery("#data").DataTable({
						ordering: false
					})
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "User Logs"
		}
	}
</script>