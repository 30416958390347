<template>
	<div class="page-breadcrumb bg-white">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12">
                <h4 class="page-title">Invoices</h4>
            </div>
            <div class="col-lg-9 col-sm-8 col-md-8 col-xs-12">
                <div class="d-md-flex">
                    <ol class="breadcrumb ms-auto">
                        <li><a href="#" class="fw-normal">Invoices</a></li>
                    </ol>
                </div>
            </div>
        </div>
        <!-- /.col-lg-12 -->
    </div>

    <div class="container-fluid">
    	<div class="row" v-if="hasPermission('addInvoice')">
            <div class="col-sm-12">
                <div class="white-box">
                	<h3 class="box-title">Edit Invoice</h3>

                	<div class="text-center" v-if="loading">
                		<div class="spinner-border"></div>
                	</div>

                	<form v-on:submit.prevent="editInvoice" v-if="invoice != null">
                		<div class="form-group">
							<label class="form-label">User</label>
							<input type="email" name="email" id="userEmail" placeholder="Enter email (leave empty for un-registered)" class="form-control" v-model="invoice.email" />
						</div>

						<div class="form-group">
							<label class="form-label">Invoice ID</label>
							<input type="text" name="invoiceId" placeholder="Enter Invoice ID" class="form-control" v-model="invoice.invoiceId" />
						</div>

						<div class="form-group">
							<label class="form-label">Name</label>
							<input type="text" name="name" placeholder="Enter name" class="form-control" required v-model="invoice.name" />
						</div>

						<div class="form-group">
							<label class="form-label">Mobile</label>
							<input type="text" name="mobile" placeholder="Enter mobile" class="form-control" v-model="invoice.mobile" />
						</div>
						
						<div class="form-group">
							<label class="form-label">Address</label>
							<textarea class="form-control" name="address" placeholder="Enter address" rows="5" v-model="invoice.address"></textarea>
						</div>

						<div class="form-group">
                            <label class="form-label">Select Payment Method</label>
                            <select name="paymentMethod" required class="form-control" v-on:change="onchangePaymentMethod" v-model="invoice.paymentMethod">
                                <option value="">Select</option>
                                <option value="bank">Bank</option>
                                <option value="cash">Cash</option>
                                <option value="cheque">Cheque</option>
                                <option value="paypal">PayPal</option>
                            </select>
                        </div>

                        <div class="form-group" v-if="invoice.paymentMethod == 'bank'">
                    		<label>Select Bank</label>
                    		<select name="bank" class="form-control" v-model="invoice.bank">
                    			<option v-for="(b, index) in banks" v-bind:value="JSON.stringify(b)" v-text="b.accountTitle + ' ' + b.accountNumber"></option>
                    		</select>
                    	</div>

                    	<div class="form-group" v-if="paymentMethod == 'cheque'">
                    		<label>Enter cheque number</label>
                    		<input type="text" name="cheque" class="form-control" v-model="invoice.cheque" />
                    	</div>
						
						<div class="form-group">
							<label class="form-label">Invoice For</label>
							<select name="invoiceFor"
								v-model="invoice.invoiceFor"
								required
								class="form-control"
								v-on:change="onchangeDepositFor">
								<!-- <option value="">Select Invoice For</option> -->
                                <!-- <option value="advanceCarPayment">Advance Car Payment</option> -->
								<!-- <option value="carPayment">Car Payment</option> -->
								<option value="securityDeposit">Security Deposit</option>
							</select>
						</div>

						<div class="form-group" v-if="invoice.invoiceFor == 'carPayment' || invoice.invoiceFor == 'advanceCarPayment'">
							<label class="form-label">Car</label>

							<select name="auctionCarId" class="form-control" v-model="invoice.auctionCarId">
                                <option v-for="(car, index) in cars" v-text="car.MARKA_NAME + ' ' + car.MODEL_NAME + ' ' + car.YEAR + ' (LOT: ' + car.LOT + ')'" v-bind:value="car.ID"></option>
                            </select>
						</div>
						
						<div class="form-group">
							<label class="form-label">Currency</label>
							<input name="currency" required class="form-control" v-model="invoice.currency" />
						</div>

						<div class="form-group">
							<label class="form-label">Amount</label>
							<input type="number" name="amount" placeholder="Amount" class="form-control" required style="margin-top: 10px;" v-model="invoice.amount" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Banking Fee</label>
							<input type="text" name="bankingFee" placeholder="Banking fee" class="form-control" required v-model="invoice.bankingFee" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Shipping Charges</label>
							<input type="text" name="shippingCharges" placeholder="Shipping Charges" class="form-control" required v-model="invoice.shippingCharges" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Taxes</label>
							<input type="text" name="taxes" placeholder="Taxes" class="form-control" required v-model="invoice.taxes" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Adjust Security Deposit</label>
							<input type="text" name="securityDepositAdjusted" placeholder="Security Deposit" class="form-control" required v-model="invoice.securityDepositAdjusted" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Total Previous Car Balance</label>
							<input type="text" name="totalPreviousCarBalance" placeholder="Total Previous Car Balance" class="form-control" required v-model="invoice.totalPreviousCarBalance" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Shipping Discount</label>
							<input type="text" name="shippingDiscount" placeholder="Shipping Discount" class="form-control" required v-model="invoice.shippingDiscount" />
						</div>
                        
                        <div class="form-group">
							<label class="form-label">Balance Amount</label>
							<input type="text" name="balanceAmount" placeholder="Balance Amount" class="form-control" required v-model="invoice.balanceAmount" />
						</div>
						
						<div class="form-group">
							<label class="form-label">VAT</label>
							<input type="text" name="vat" placeholder="Enter VAT" class="form-control" v-model="invoice.vat" />
						</div>

						<div class="form-group">
							<label class="form-label">Due Date</label>
							<input type="text" name="dueDate" placeholder="Enter Due Date" class="form-control dueDate" v-model="invoice.dueDate" />
						</div>

						<div class="form-group">
							<label class="form-label">Status</label>

							<select name="status" class="form-control" v-model="invoice.status">
                                <option value="pending">Pending</option>
                                <option value="processed">Processed</option>
                                <option value="paid">Paid</option>
                                <option value="declined">Declined</option>
                            </select>
						</div>

						<button type="submit" name="submit" class="btn btn-primary" style="color: white;">
							Edit Invoice

							<i class="fa fa-spinner fa-spin" v-if="isUpdating"></i>
						</button>
                	</form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

	import axios from "axios"
	import swal from "sweetalert2"
	import jQuery from "jquery"

	import "../../assets/datetimepicker/jquery.datetimepicker.min.css"
    import "../../assets/datetimepicker/jquery.datetimepicker.full.js"

	export default {
		name: "InvoiceEditComponent",

		data() {
			return {
				id: this.$route.params.id || 0,
				invoice: null,
				loading: false,
				isUpdating: false,
				banks: []
			}
		},

		methods: {

			async editInvoice() {
				const form = event.target
				const formData = new FormData(form)
				formData.append("id", this.id)
				this.isUpdating = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/invoices/update",
						formData,
						{
							headers: this.$headers
						}
					)
					swal.fire("Edit Invoice", response.data.message, response.data.status)
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.isUpdating = false
				}
			},

			async getData() {
				const formData = new FormData()
				formData.append("id", this.id)
				this.loading = true

				try {
					const response = await axios.post(
						this.$apiURL + "/admin/invoices/fetch",
						formData,
						{
							headers: this.$headers
						}
					)

					if (response.data.status == "success") {
						this.invoice = response.data.data
						this.banks = response.data.banks
					} else {
						swal.fire("Error", response.data.message, "error")
					}
				} catch (error) {
					if (error?.response?.status == 401) {
						swal.fire("Error", error.response.data.message || "Unauthorized", "error")
					}
				} finally {
					this.loading = false
				}
			}
		},

		mounted() {
			this.getData()
			document.title = "Edit Invoice"

			jQuery(".dueDate").datetimepicker({
                format: "Y-m-d",
                timepicker: false,
                scrollMonth: false
            })
		}
	}
</script>